import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import {Tabs, Tab} from 'vue3-tabs-component';
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createI18n} from "vue-i18n";
import de from "./locales/de.json"
import en from "./locales/en.json"
import pl from "./locales/pl.json"
import { createHead } from "@vueuse/head"
import store from "./store";
import VueLazyload from 'vue-lazyload'
const vuetify = createVuetify({
    components,
    directives,
})
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faGaugeHigh } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { faComment  } from '@fortawesome/free-solid-svg-icons'
import { faMotorcycle } from '@fortawesome/free-solid-svg-icons'
import { faGem, faHouseUser, faLeaf, faUtensils, faRoad, faPuzzlePiece  } from '@fortawesome/free-solid-svg-icons'
import { faCheck  } from '@fortawesome/free-solid-svg-icons'
import { faPeopleGroup  } from '@fortawesome/free-solid-svg-icons'
import { faX  } from '@fortawesome/free-solid-svg-icons'
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
library.add(faPuzzlePiece,faRoad,faUtensils,faLeaf,faHouseUser,faPeopleGroup,faFire,faInfinity,faGem,faChevronLeft,faChevronRight,faX,faCheck,faEnvelopeCircleCheck,faComment,faLocationDot,faChevronUp,faTiktok,faFacebook,faYoutube,faChevronDown,faMotorcycle,faUserSecret,faEye,faEyeSlash,faMapLocationDot,faGaugeHigh,faInstagram)
function language() {
    var nav = window.navigator,
        browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
        i,
        language;

    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {

            language = nav.languages[i];
            if (language && language.length) {
                if(!language.startsWith("pl") || !language.startsWith("en") || !language.startsWith("de")){
                    language = "en";
                }
                localStorage.setItem("locale",language);
                return language;
            }
        }
    }

    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            if(!language.startsWith("pl") || !language.startsWith("en") || !language.startsWith("de")){
                language = "en";
            }
            localStorage.setItem("locale",language);
            return language;
        }
    }

    return "en";
}
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem("locale") ?? language(),
    fallbackLocale: "en_GB",
    messages: {
        "pl_PL": pl,
        "pl": pl,
        "en": en,
        "en_AS": en,
        "en_AU": en,
        "en_BE": en,
        "en_BZ": en,
        "en_BW": en,
        "en_CA": en,
        "en_GU": en,
        "en_HK": en,
        "en_IN": en,
        "en_IE": en,
        "en_IL": en,
        "en_JM": en,
        "en_MT": en,
        "en_MH": en,
        "en_MU": en,
        "en_NA": en,
        "en_NZ": en,
        "en_MP": en,
        "en_PK": en,
        "en_PH": en,
        "en_SG": en,
        "en_ZA": en,
        "en_TT": en,
        "en_UM": en,
        "en_VI": en,
        "en_GB": en,
        "en_US": en,
        "en_ZW": en,
        "de": de,
        "de-AT": de,
        "de-CH": de,
        "de-DE": de,
        "de-LI": de,
        "de-LU": de,
    }
})
const app = createApp(App);
const head = createHead()
app.use(router).use(head).use(store).use(VueLazyload).use(i18n).use(vuetify).component('tabs', Tabs).component('tab', Tab).component('font-awesome-icon', FontAwesomeIcon).use(CKEditor).mount('#app')
