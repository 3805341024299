import http from './service-base';

class EmailService {
    sendEmail(email, customerName, subject, content){
       return http
            .post( 'api/email/sendEmail', {
                emailSender: email,
                name: customerName,
                subject: subject,
                content: content
            })
    }
}

export default new EmailService();
