<template>
  <div class="flex-center-col w-11/12 lg:w-7/12 3xl:w-6/12 mx-auto mt-52">
    <h3 class="mb-8 lg:mb-14 text-dark-green text-2xl lg:text-3xl font-bold tracking-wider">{{
        offerData.title
      }}</h3>
    <p class="text-lg lg:text-xl tracking-wide leading-[276%] text-gray">{{ offerData.desc }}</p>
    <div
        class="flex items-center lg:items-start justify-center md:justify-between flex-col md:flex-row flex-wrap mt-4 lg:mt-10 w-full md:w-9/12 lg:w-full">
      <div v-for="pack in offerData.packagesInclude"
           :key="pack"
           class="flex items-center justify-center md:items-start justify-start flex-col my-6 lg:my-10 w-64">
        <font-awesome-icon :icon="pack.packageIcon" class="text-medium-green text-2xl"/>
        <h5 class="my-1 text-lg font-medium">{{ pack.packageTitle }}</h5>
        <div v-for="include in pack.includes" :key="include" class="flex items-start justify-start">
          <p class="lg:text-start mb-1 text-sm">
            <font-awesome-icon :class="{ 'text-red-500': include.tick }"
                               :icon="include.tick ? 'fa-solid fa-x' : 'fa-solid fa-check'"
                               class="w-6 text-medium-green text-xs"/>
            {{ include.packageInfo }}
          </p>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-center lg:items-end lg:justify-end mt-10">
      <button class="offer-btn" @click="clickContact">{{t('events.book')}}</button>
    </div>
  </div>

</template>

<script>
import {ref} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: "OfferComponent",
  props:{
    data: {
      type: Object,
      required: true
    }
  },
  setup(props, {emit}){
    const openForm = ref(false);

    function clickContact(){
      emit('openContact');
    }
    const {t} = useI18n()
    const offerData = ref(props.data);
    return {openForm,offerData,t,clickContact};
  }
}
</script>

<style scoped>

</style>
