<template>
  <div class="flex-center-col px-5 relative">
    <button class="relative " @click="showMore = !showMore">
      <p class="text-xs flex items-center">
        <img :src="flagSrc" alt="flag" class="w-4 h-4 mr-2"/>
        {{currentLanguage}}
        <font-awesome-icon :icon="showMore ? 'chevron-up' : 'chevron-down'" class="text-[10px]"/>
      </p>
    </button>
    <div class="flex items-start flex-col absolute bottom-[-80px] lg:bottom-[-83px] py-2 px-2 text-white  bg-white bg-opacity-30 backdrop-blur-lg h-24 lg:h-20 text-xs text-gray" v-if="showMore">
      <button class="text-sm lg:text-xs flex items-center" @click="onItemClick('pl')">
        <img :src="require('@/assets/pl.png')" alt="flag" class="w-4 h-4 mr-2"/>Polski
      </button>
      <button class="text-sm lg:text-xs my-2 lg:my-2 flex items-center" @click="onItemClick('de')">
        <img :src="require('@/assets/de.png')" alt="flag" class="w-4 h-4 mr-2"/>Deutsch
      </button>
      <button class="text-sm lg:text-xs flex items-center" @click="onItemClick('en')">
        <img :src="require('@/assets/en.png')" alt="flag" class="w-4 h-4 mr-2"/>English
      </button>
    </div>
  </div>
</template>

<script>
import {ref, computed} from "vue";
import {useI18n} from "vue-i18n";
import {useHead} from "unhead";

export default {
  setup(props, context){
    const t = useI18n();
    const showMore = ref(false)
    const currentLanguage = ref('EN')
    const changeLanguage = ref(false)
    const locale = ref({
      label:'English',
      value:'en',
      icon:'EN'
    })
    const chooseLanguage = ref([
      {
        label:'English',
        value:'en',
        icon:'EN'
      },
      {
        label:'Polish',
        value:'pl',
        icon:'PL'
      },
      {
        label:'German',
        value:'de',
        icon:'DE'
      }
    ]);

    // Here is the computed property for the flag source
    const flagSrc = computed(() => {
      return require(`@/assets/${locale.value.value}.png`)
    })

    function findLocale(){
      let loc = localStorage.getItem("locale");
      if(loc==null){
        loc = navigator.languages[0];
      }
      const str = loc.includes("-")?loc.split('-')[0] : loc;
      let val = chooseLanguage.value.find(x => x.value == str);
      if(!val){
        val = chooseLanguage.value.find(x=>x.value=='en');
      }
      localStorage.setItem("locale",val.value);
      locale.value = val;
      currentLanguage.value = locale.value.icon;
      useHead({
        htmlAttrs: {
          lang: val.value
        }
      })
      return val;
    }

    function onItemClick(val){
      const newLocale = chooseLanguage.value.find(x => x.value == val);
      locale.value = newLocale;
      localStorage.setItem("locale", locale.value.value)
      currentLanguage.value = locale.value.icon;
      t.locale.value = locale.value.value;
      context.emit('update', locale.value.value);
      useHead({
        htmlAttrs: {
          lang: locale.value.value
        }
      })
      showMore.value = false;
    }

    findLocale();
    return {
      showMore,
      currentLanguage,
      changeLanguage,
      locale,
      chooseLanguage,onItemClick, flagSrc
    }
  }
}
</script>


<style scoped>

</style>
