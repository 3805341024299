<template>
  <div class="p-12 m-3 border border-gray-two border-solid">
    <h1 class="mb-2">Create timeline</h1>
    <div v-for="(d,dIndex) in dates" :key="d.id" class="mt-12">
      <v-text-field v-model="d.data" clearable label="Nazwa wydarzenia" placeholder="np. Przylot, dzień 1"
                    variant="solo"/>
      <div class="flex flex-col py-12">
        <div v-for="(i,iIndex) in d.dataInfo" :key="i.id" class="w-full">
          <div class="flex-row flex w-full p-2 basis-1/4  flex-1">
            <v-text-field v-model="i.timeFrom" clearable label="Time From" placeholder="np. 9:30 " variant="solo"/>
            <v-text-field v-model="i.timeTo" class="mx-2" clearable label="Time To" placeholder="np. 14:00"
                          variant="solo"/>
            <v-text-field v-model="i.timeTitle" clearable label="Time Title" placeholder="np. Obiad" variant="solo"/>
            <v-text-field v-model="i.timeDescription" class="mx-2" clearable label="Time Description"
                          placeholder="np. Dzień pierwszy treningu..." variant="solo"/>
            <v-btn color="primary" @click="removeDataInfo(d,iIndex)">Remove Date Info</v-btn>
          </div>
        </div>
      </div>
      <div class="flex">
        <v-btn class="mr-2" color="secondary" @click="addDataInfo(d)">Add Date Info</v-btn>
        <v-btn color="primary" @click="removeDate(dIndex)">Remove Date</v-btn>
      </div>
    </div>
    <div class="flex justify-center align-center gap-4">
      <v-btn color="primary" @click="addDate">Add Date</v-btn>

    </div>
  </div>
</template>

<script>
import { ref, watch} from "vue";

export default {
  name: "DateCreatorComponent",
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const dates = ref(props.modelValue ?? [
      {
        id: 0,
        data: "",
        dataInfo: [
          {
            id: 0,
            timeFrom: "",
            timeTo: "",
            timeTitle: "",
            timeDescription: "",
          },
        ],
      },
    ]);

    watch(() => props.modelValue, (newVal) => {
      dates.value = newVal;
    });
    watch(dates, (newVal) => {
      emit('update:modelValue', newVal);
    });

    function addDate() {
      const newDate = {
        data: "",
        dataInfo: [
          {
            timeFrom: "",
            timeTo: "",
            timeTitle: "",
            timeDescription: ""
          }
        ]
      }
      dates.value.push(newDate)
    }

    function removeDate(index) {
      dates.value.splice(index, 1)
    }

    function addDataInfo(d) {
      const newDataInfo = {
        timeFrom: "",
        timeTo: "",
        timeTitle: "",
        timeDescription: ""
      }
      d.dataInfo.push(newDataInfo)
    }

    function removeDataInfo(d,ind) {
      d.dataInfo.splice(ind, 1)
    }

    return {
      dates,
      addDate,
      removeDate,
      removeDataInfo, addDataInfo
    }
  }
}
</script>

<style scoped>

</style>
