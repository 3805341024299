<template>
  <div class="flex-center-col h-full w-full">
    <div class="bg-img relative">
      <div class="absolute inset-0 dark-color"></div>
      <div class="flex-center absolute inset-0">
        <h1 class="title text-6xl lg:text-9xl uppercase tracking-widest font-medium">FAQ</h1>
      </div>
    </div>
    <FaqList/>
    <FooterSection/>
  </div>
</template>

<script>

import FooterSection from "@/components/FooterSection";
import FaqList from "@/components/FaqList";
export default {
  components: {FooterSection,FaqList},
  setup() {
    return {
    };
},

}
</script>

<style scoped>
.bg-img {
  background-image: url("@/assets/homeimages/faq1.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 80vh;
  width:100%;
}
.dark-color {
  background-color:rgba(0,0,0,.5);
}
.title {
  color: transparent;
  -webkit-text-stroke:1.5px white;
  -moz-text-stroke: 1.5px white;
  text-stroke: 1.5px white;
}

@media (min-width: 768px) {
  .bg-img {
    background-image: url("@/assets/homeimages/faq2d.jpg");
    background-position: center;
  }
}
</style>