
<template>
  <div class="flex-cs-col w-full h-full pt-20 pb-32 text-black relative">
    <p class=" hidden md:flex absolute left-[-5%] md:left-[-53%] lg:left-[-48%] mxl:left-[-45%] lxl:left-[-40%] xl:left-[-50%] 2xl:left-[-53%] 3xl:left-[-40%] top-[50%] xl:top-[50%] md:top-[55%] ver md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl z-20 text-dark-green tracking-wide md:tracking-widest font-bold uppercase mix-blend-plus-lighter">Your next enduro adventure</p>
    <div class="flex-cs-col xxs:mb-96 xs:mb-64 md:mb-0 md:pb-24 lg:pb-44 md:h-1/2 h-screen w-11/12 3xl:w-10/12 mx-auto">
      <div class="flex-center md:items-start md:justify-between flex-col md:flex-row w-11/12 md:w-full lg:w-11/12 mx-auto ">
        <div class="flex-center-col md:justify-start e my-4 lg:my-8 text-gray" data-aos="fade-down">
          <p class="text-xl md:text-lg ld:text-xl font-bold text-medium-green lg:text-gray" >{{ $t('about.t1') }} </p>
          <span class="text-base md:text-sm mt-2">{{ $t('about.st1') }}</span>
        </div>
        <div class="flex-center-col md:justify-start e my-4 lg:my-8 text-gray" data-aos="fade-down" data-aos-delay="100">
          <p class="text-xl md:text-lg ld:text-xl font-bold text-medium-green lg:text-gray">{{ $t('about.t2') }}</p>
          <span class="text-base md:text-sm mt-2">{{ $t('about.st2') }}</span>
        </div>
        <div class="flex-center-col md:justify-start e my-4 lg:my-8 text-gray" data-aos="fade-down" data-aos-delay="250">
          <font-awesome-icon icon="fa-solid fa-infinity" class="text-xl md:text-lg lg:text-xl text-medium-green lg:text-gray" />
          <span class="text-base md:text-sm mt-5">{{ $t('about.t3') }}</span>
        </div>
        <div class="flex-center-col md:justify-start e my-4 lg:my-8 text-gray" data-aos="fade-down"  data-aos-delay="300">
          <p class="text-xl md:text-lg ld:text-xl font-bold text-medium-green lg:text-gray">100%</p>
          <span class="text-base md:text-sm mt-2">{{ $t('about.t4') }}</span>
        </div>
      </div>
      <div class="flex-center-col w-full xl:w-7/12 mx-auto mt-24 text-gray">
        <p class="text-xl xl:text-3xl leading-loose tracking-widest text-start">{{ $t("about.desc1") }}</p>
        <p class="mt-10 line-hh tracking-wider text-start text-lg xl:text-2xl font-thin"> {{ $t("about.desc2") }}</p>
      </div>
    </div>
    <div class="h-[90vh] w-full relative">
      <div class="flex-center-col absolute inset-0 img-bg">
        <div class="flex-center-col px-4 xl:px-0 w-full md:w-9/12 xl:w-6/12 mx-auto">
          <p class="text-start text-white text-lg xl:text-2xl leading-loose tracking-wider">{{ $t("about.desc3") }}
          </p>
          <div class="flex-start w-full mt-10">
            <router-link to="/upcoming" class="px-16 py-3 bg-medium-green shadow-sm shadow-medium-green text-black font-medium rounded-lg mt-4 text-xl">{{ $t("about.book") }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center xl:items-start justify-between flex-col xl:flex-row px-4 xl:px-0 w-full md:w-7/12 xl:w-9/12 mx-auto pt-20 lg:pt-28">
      <div class="flex-center-col w-full xl:w-80 my-4 md:my-8 xl:my-0" v-for="item in whatWeDo" :key="item">
        <font-awesome-icon :icon="item.icon" class="mb-2 text-4xl text-medium-green"/>
        <h4 class="font-medium text-gray">{{ $t("about.whatdo."+item.id+".head") }}</h4>
        <p class="text-sm text-gray">{{ $t("about.whatdo."+item.id+".des") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {reactive} from "vue";
export default {
  setup(){
    const values = reactive([
      {
       headName:'Over 30 years',
       des:'Riding and teaching experience'
      },
      {
       headName:'Over 1200',
       des:'Riders trained'
      },
      {
       headName:'Endless',
       des:'Riding trails'
      },
      {
       headName:'100%',
       des:'Participants satisfaction'
      },
    ])
    const whatWeDo = reactive ([
      {
        icon: 'fa-map-location-dot',
        id: 0,
        head: 'Where will you ride',
        des: 'Cyprus is a heaven for enduro riding.Endless possibilities of riding trails for all skill levels, in scenic surroundings.'
      },
      {
        id:1,
        icon: 'fa-gauge-high',
        head: 'What will you ride',
        des: 'We offer top of the line motorcycles for every level and preference, 20+ different bikes for every level. Four stroke beginner bikes, 2022 & 2023 two stroke models of GasGas (250 & 300 cc) and more.'
      },
      {
        id: 2,
        icon: 'fa-motorcycle',
        head: 'Who will you ride with',
        des: 'Our trainers have over 30 years riding experience each. With over 10 years teaching experience, they have trained over 1200 riders. Their true (and contagious!) passion for enduro combined with excellent teaching techniques will ensure that you develop your skills and fall in love with the sport even more.\n'
      }
    ])
    return{
      values,whatWeDo
    }
  }
}
</script>
<style scoped>
.img-bg {
  background-image: url("@/assets/photo2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

}

@media (min-width: 768px) {
  .img-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .e {
    width: 500px;
  }

  .e:nth-child(-n+3) {
    height: 80px;
    border-right: 1.5px solid #57CCBC;
  }

  .e:nth-child(4)::after {
    content: none;

  }
}
.line-hh{
  line-height: 150%;
}
</style>
