import PostsService from "@/services/posts-service";
import ImageService from "@/services/image-service";
import * as Vue from "@vue/reactivity";

export const posts = {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    posts: [],
    realPosts: [],
    photos: [],
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_REAL_POSTS(state, posts){
      state.realPosts.push(posts);
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_PHOTOS(state, photos) {
      state.photos.push(photos);
      state.photos.sort((a, b) => a.id - b.id);
    },
    SET_POSTS(state, posts) {
      state.posts = posts;
    },
    ADD_POST(state, post) {
      state.posts.push(post);
    },
    UPDATE_POST(state, {id, updatedPost}) {
      const index = state.posts.findIndex(post => post.id === id);
      if (index !== -1) {
        state.posts.splice(index, 1, updatedPost);
      }
    },
    DELETE_POST(state, id) {
      state.posts = state.posts.filter(post => post.id !== id);
    },
    DUPLICATE_POST(state, duplicatedPost) {
      state.posts.push(duplicatedPost);
    },
  },
  actions: {
    fetchPosts({commit, dispatch}) {
      commit('SET_LOADING', true);

      return PostsService.getAllPosts()
      .then(response => {
        commit('SET_POSTS', response.data);
        commit('SET_LOADING', false);
        Promise.resolve(response.data);
        response.data.forEach(i => {
          const id = i.id;
          dispatch('getRealPost', id)
        })

      })
      .catch(error => {
        commit('SET_ERROR', error);
        commit('SET_LOADING', false);
        return Promise.reject(error);
      });
    },
    createPost({commit}, {data, token}) {
      return PostsService.createPost(data, token)
      .then(response => {
        commit('ADD_POST', response.data);
        return Promise.resolve(response.data);
      });
    },
    updatePost({commit, state}, {data, id, token}) {
      return PostsService.updatePost(data, id, token)
      .then(response => {
        commit('UPDATE_POST', {id, updatedPost: response.data});
        const index = state.posts.findIndex(post => post.id === id);
        if (index !== -1) {
          commit('SET_POSTS', [
            ...state.posts.slice(0, index),
            response.data,
            ...state.posts.slice(index + 1)
          ]);
        }
        return Promise.resolve(response.data);

      });
    },
    deletePost({commit, state}, {id, token}) {
      return PostsService.delete(id, token)
      .then(response => {
        commit('DELETE_POST', id);
        const index = state.posts.findIndex(post => post.id === id);
        if (index !== -1) {
          commit('SET_POSTS', [
            ...state.posts.slice(0, index),
            ...state.posts.slice(index + 1)
          ]);
        }
        return Promise.resolve(response.data);

      });
    },
    getRealPost({commit, state}, id) {
      commit('SET_LOADING', true);
      const realPost = Vue.toRaw(state.realPosts);
      const existingPost = realPost.find(post => {
        return post.id == id;
      });
      if (existingPost) {
        commit('SET_LOADING', false);
        return Promise.resolve(existingPost);
      }
      return PostsService.getPost(id)
      .then(response => {
        commit('SET_REAL_POSTS', response.data);
        commit('SET_LOADING', false);
        return Promise.resolve(response.data);
      });
    },
    duplicatePost({commit}, {id, token}) {
      return PostsService.duplicate(id, token)
      .then(response => {
        commit('DUPLICATE_POST', response.data);
        return Promise.resolve(response.data);
      });
    },
    async fetchImages({commit, state}, i) {
      const imgsState = Vue.toRaw(state.photos);
      const foundObject = imgsState.find((photo) => photo.id == i);
      if (foundObject) {
        return foundObject;
      }
      return ImageService.getImageUrl(i)
      .then((response) => {
        const photoObject = {
          id: i,
          url: URL.createObjectURL(response.data),
        };
        commit('SET_PHOTOS', photoObject);

        return photoObject;
      })
      .catch((error) => {
        console.error('Error fetching image:', error);
        return null;
      });

    },
    uploadImage({file, percentCallback}) {
      return ImageService.uploadFile(file, percentCallback);
    },
    deleteImage(id) {
      return ImageService.deleteImage(id);
    },
  },
  getters: {
    isLoading(state) {
      return state.loading;
    },
    getPosts(state) {
      return state.posts;
    },
    getImages(state) {
      return state.photos;
    },
    getAllPostData(state) {
      return state.realPosts;
    },
    getRealPostById: (state) => (id) => {
      const data = state.realPosts.find((post) => post.id === id);
      return data;
    },
  },
};
