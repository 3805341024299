<template>
  <div class="w-screen h-screen bg-gray pt-52">
    <div class="flex-center-col w-11/12 mx-auto ">
      <div class="w-full flex items-end justify-end">
        <router-link to="/post" class="px-8 py-2 bg-medium-white text-gray rounded-lg">Add new event</router-link>
      </div>
    </div>
    <div class="w-full lg:w-1/2 mx-auto h-full mt-8 py-2 px-2">
      <div v-if="isSending">
        <div v-if="isSending" class="fingerprint-spinner">
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
        </div>
      </div>

      <table class="fl-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Active</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="event in events" :key="event.id" class="border-r border-gray lg:border-r-0">
          <td>{{ event.id }}</td>
          <td>{{ event.title }}</td>
          <td>{{ event.active }}</td>
          <td>
            <button @click="deletePost(event.id)" class="mr-4 font-bold text-red-700">Delete</button>
            <button @click="duplicate(event.id)" class="mr-4 font-bold">Duplicate</button>
            <button @click="edit(event.id)" class="mr-4 font-bold">Edit</button>
            <button @click="changeValue(event.id,!event.active)" class="font-bold">Change Active</button>
          </td>
        </tr>
        </tbody>
      </table>

      <div v-if="events.length<=0">
      <span>No results loaded.</span>
      </div>
    </div>
  </div>

</template>

<script>
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import PostsService from "@/services/posts-service";

export default {
  name: "AdminPanel",
  setup(){
    const events = ref([]);
    const isSending = ref(false);
    const error = ref('');
    const route = useRouter();
    onMounted(()=>{
      isSending.value = true;
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user) {
        route.push('/admin');
      }
      PostsService.getAll().then(res=>{
        res.data.map((d)=>{
          const id = d.id;
          const title = d.titlePl??d.titleEng??d.titleGer;
          const dd = {
            id:id,
            title: title,
            active: d.active
          }
          events.value.push(dd);
        });
        isSending.value = false;
      },err=>{
        error.value = err;
        isSending.value = false;
      });
    })
function edit(id){
route.push("/post/"+id);
}
    function deletePost(id){
      const user = JSON.parse(localStorage.getItem('user'));
      PostsService.delete(id,user).then(i => {
        console.log(i);
        const indexToRemove = events.value.findIndex((event) => event.id === id);
        indexToRemove !== -1 ? events.value.splice(indexToRemove, 1) : null;
        alert("Post deleted!");
      }, err => {
        alert("Error during deleting post:")
        console.log(err);
      });
    }

    function duplicate(id){
      const user = JSON.parse(localStorage.getItem('user'));
      PostsService.duplicate(id,user).then(i => {
        console.log(i.id);
        alert("Post duplicated!");
      }, err => {
        alert("Error during duplicating post:")
        console.log(err);
      });
    }

    function changeValue(id,val){
      const user = JSON.parse(localStorage.getItem('user'));
      PostsService.changeActive(id,val,user).then(i => {
        console.log(i.id);
        alert("Post "+(val?"activated!":"deactivated!"));
      }, err => {
        alert("Error during changing post activation:")
        console.log(err);
      });
    }
    return{events,deletePost,duplicate,edit,isSending,changeValue}
  }
}
</script>

<style scoped>
.fl-table {
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  border: .5px solid #232323;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: #DCDCDC;
}

.fl-table td, .fl-table th {
  text-align: center;
  padding: 8px;
}

.fl-table td {
  border-right: 1px solid #232323;
  font-size: 12px;
}

.fl-table thead th {
  color: #DCDCDC;
  background: #4FC3A1;
}


.fl-table thead th:nth-child(odd) {
  color: #DCDCDC;
  background: #324960;
}

.fl-table tr:nth-child(even) {
  background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }
  .table-wrapper:before{
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  .fl-table thead, .fl-table tbody, .fl-table thead th {
    display: block;
  }
  .fl-table thead th:last-child{
    border-bottom: none;
  }
  .fl-table thead {
    float: left;
  }
  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .fl-table td, .fl-table th {
    padding: 20px .625em .625em .625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
    display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }
  .fl-table tr:nth-child(even) {
    background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
    background: #F8F8F8;
    border-right: 1px solid #E6E4E4;
  }
  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #E6E4E4;
  }
  .fl-table tbody td {
    display: block;
    text-align: center;
  }
}
</style>
