<template>
  <div class="flex-center-col pt-44 w-11/12 mx-auto" data-aos="fade-down">
    <img class="object-cover object-center" :src="require('@/assets/gallery/6.jpg')" data-aos="fade-up" />
    <div class="flex w-full">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/14.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/20.jpg')" data-aos="fade-up" />
    </div>
    <div class="flex w-full my-4 lg:my-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/19.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/8.jpg')" data-aos="fade-up" />
    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full" :src="require('@/assets/gallery/15.jpg')" data-aos="fade-up" />
    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/12.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/8.jpg')" data-aos="fade-up" />
    </div>
    <div class="flex w-full my-4 lg:my-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/21.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/2.jpg')" data-aos="fade-up" />
    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full" :src="require('@/assets/gallery/11.jpg')" data-aos="fade-up" />
    <v-lazy-image class="object-cover object-center my-4 lg:my-8 h-[234px] lg:h-[900px] w-full" :src="require('@/assets/gallery/1.jpg')" data-aos="fade-up" />

    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/3.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/5.jpg')" data-aos="fade-up" />
    </div>
    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/8.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/13.jpg')" data-aos="fade-up" />

    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full my-4 lg:my-8" :src="require('@/assets/gallery/4.jpg')" data-aos="fade-up" />

    <div class="flex flex-col lg:flex-row w-full">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[262px] lg:h-[534px] w-full lg:w-1/2" :src="require('@/assets/gallery/16.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[534px] mt-4 lg:mt-0 w-full lg:w-4/5" :src="require('@/assets/gallery/10.jpg')" data-aos="fade-up" />
    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full my-4 lg:my-8" :src="require('@/assets/gallery/17.jpg')" data-aos="fade-up" />
    <div class="flex w-full">
      <v-lazy-image class="object-cover object-center mr-2 lg:mr-8 h-[148px] lg:h-[639px] w-1/2" :src="require('@/assets/gallery/7.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[148px] lg:h-[639px] w-1/2" :src="require('@/assets/gallery/22.jpg')" data-aos="fade-up" />
    </div>
    <div class="flex mt-4 lg:mt-8 w-full">
      <v-lazy-image class="object-cover object-center mr-2 lg:mr-8 h-[148px] lg:h-[639px] w-1/3" :src="require('@/assets/gallery/23.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center mr-2 lg:mr-8 h-[148px] lg:h-[639px] w-1/3" :src="require('@/assets/gallery/24.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[148px] lg:h-[639px] w-1/3" :src="require('@/assets/gallery/25.jpg')" data-aos="fade-up" />
    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full my-4 lg:my-8" :src="require('@/assets/gallery/26.jpg')" data-aos="fade-up" />
    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/28.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/29.jpg')" data-aos="fade-up" />

    </div>
    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/30.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/31.jpg')" data-aos="fade-up" />

    </div>
    <v-lazy-image class="object-cover object-center h-[234px] lg:h-[900px] w-full my-4 lg:my-8" :src="require('@/assets/gallery/32.jpg')" data-aos="fade-up" />
    <div class="flex w-full mt-4 lg:mt-8">
      <v-lazy-image class="object-cover object-center mr-4 lg:mr-8 h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/33.jpg')" data-aos="fade-up" />
      <v-lazy-image class="object-cover object-center h-[225px] lg:h-[900px] w-1/2" :src="require('@/assets/gallery/34.jpg')" data-aos="fade-up" />

    </div>

  </div>
  <footer-section class="mt-8"/>
</template>

<script>
import FooterSection from "@/components/FooterSection";
import VLazyImage from "v-lazy-image";
export default {
  components: {FooterSection,VLazyImage}
}
</script>

<style scoped>
</style>
