<template>
  <div class="flex-center-col w-11/12 lg:w-8/12 mx-auto text-black py-16">
    <h2 class="text-3xl lg:text-6xl font-bold">{{$t('nav.faq-1')}} <span class="text-medium-green">{{$t('nav.faq-2')}}</span></h2>
    <div v-for="faq in faqs" :key="faq.id" class="flex-center-col mt-3 lg:mt-8 w-full">
      <div :class="faq.isOpen ? 'bg-medium-white' : 'bg-transparent'"
           class="py-5 px-5 w-full shadow-sm shadow-gray-400 my-2 rounded-lg">
        <div
            class="flex cursor-pointer items-center justify-between"
            role="button "
            @click="openAccordion(faq.id)"
        >
          <h3 class="text-start text-sm lg:text-lg font-medium">{{ $t('faq.' + faq.id + '.title') }}</h3>
          <font-awesome-icon :icon="faq.isOpen ? 'chevron-up' : 'chevron-down'" class="text-dark-green text-sm"/>
        </div>
        <Transition name="slide">
          <div v-if="faq.isOpen"  class="flex-start mt-6 py-2 w-full">
            <p class="text-start" v-html="parseVariables('faq.'+faq.id+'.desc')"></p>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>


<script>
import {onMounted, reactive, ref} from "vue";
import {useI18n} from 'vue-i18n'
export default {
  setup() {
    const isOpen = ref(false)
    const {t, te} = useI18n()
    function openAccordion(id) {
      faqs.forEach((faq) => {
        if (faq.id === id) {
          faq.isOpen = !faq.isOpen;
        } else {
          faq.isOpen = false;
        }
      });
    }
    const faqs = reactive([]);
    onMounted(() => {
      let max = 0;
      for (let m = 0; m <= 100; m++) {
        if (te("faq." + m)) {
          max++;
        } else {
          break;
        }
      }
      for (let k = 0; k < max; k++) {
        faqs.push({
          id: k,
          isOpen: false
        });
      }
    })
    function parseVariables(key) {
      const val = t(key);
      const newVal = val.replaceAll("%contact-href%", '<a href="/contact" class="font-medium">contact us</a>')
          .replaceAll("%upcoming%", '<a href="/upcoming" class="font-medium">Upcoming trips</a>')
          .replaceAll("%contact-form%", '<a href="/contact" class="font-medium">Contact form</a>');
      return newVal;
    }

    return {
      isOpen, open, faqs, openAccordion, t, parseVariables
    };
  },

}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>
