<template>
  <div class="flex flex-col home">
    <div class="h-screen w-full relative">
      <div class="absolute inset-0 h-full w-full head-img"></div>
      <div class="absolute inset-0 bg-black opacity-25 hidden lg:flex"></div>
      <div class="absolute inset-0 bg-black opacity-40 lg:hidden"></div>
      <div class="flex items-start justify-start absolute inset-0 w-11/12 3xl:w-10/12 mx-auto z-20">
        <div class="flex flex-col items-start justify-start pt-32 md:pt-48 lg:pt-32 text-white">
          <h2 class="text-start text-7xl md:text-9xl xl:text-[130px] 3xl:text-[140px] font-bold leading-none line">ENDURO<br/>GOATS</h2>
          <h1 class="mt-4 text-2xl md:text-3xl xl:text-4xl text-start">{{$t('home.title')}}</h1>
          <h2 class="mt-1 text-xl xl:text-2xl text-start">{{$t('home.subtitle')}}</h2>
          <router-link to="/upcoming" class="px-4 py-3 mt-4 bg-gray hover:bg-zinc-700 rounded-lg shadow-sm shadow-gray text-xl transition-colors">{{$t('home.upcomingtours')}}</router-link>
        </div>
      </div>
    </div>
  <AboutSection/>
  <OfferSection/>
  <FooterSection/>
  </div>
</template>
<script>
import AboutSection from  '@/components/home/AboutSection'
import OfferSection from  '@/components/home/OfferSection'
import FooterSection from  '@/components/FooterSection'
export default {
  name: 'HomeView',
  components: {
    AboutSection,
    OfferSection,
    FooterSection
  },
  setup(){

    return{

    }
  }
}
</script>
<style>
.head-img {
  background-image: url("@/assets/homeimages/h3.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.line {
  color: transparent;
  -webkit-text-stroke:1.5px white;
  -moz-text-stroke: 1.5px white;
  text-stroke: 1.5px white;
}
.ver {
  transform: rotate(90deg);
}

@media (min-width:786px) {
   .head-img {
     background-image: url("@/assets/homepage.jpg");
   }
}
</style>
