<template>
  <div class="flex-center-col h-full w-full" data-aos="fade-down" >
    <div class="bg-img relative">
      <div class="absolute inset-0 dark-color"></div>
      <div class="flex-center absolute inset-0">
        <h1 class="title text-5xl lg:text-9xl uppercase font-medium lg:w-11/12">{{$t("upcoming.header")}}</h1>
      </div>
    </div>
    <div class="w-full flex-center-col mt-16 text-xl lg:text-6xl font-bold text-black">
      <h2 class="uppercase">{{$t("upcoming.des")}} <span class="text-medium-green">{{$t("upcoming.des-continue")}}</span></h2>
      <h2 class="mt-8 text-xl lg:text-3xl">{{$t("upcoming.under-des")}}</h2>
    </div>
    <div class="flex items-start justify-center flex-col lg:flex-row my-24 w-full">
      <div class="flex items-center justify-center flex-col p-8 w-full lg:w-1/3 h-[387px] lg:h-[687px] bg-black test first-test relative" @mouseover="hovering = true" @mouseleave="hovering = false">
        <div class="flex-center-col text-box-1">
          <font-awesome-icon icon="fa-solid fa-fire" class="mb-4 text-4xl text-medium-green"/>
          <p class="text-3xl text-white">{{$t("upcoming.images.first-img")}} </p>
        </div>
        <p class="test-p" :class="hovering ? 'opacity-100' : 'opacity-0'">{{$t("upcoming.images.first-des")}}
        </p>
      </div>
      <div class="flex items-center justify-center flex-col p-8 bg-pink-500 w-full lg:w-1/3 h-[387px] lg:h-[687px] bg-black test second-test relative" @mouseover="hovering1 = true" @mouseleave="hovering1 = false">
        <div class="flex-center-col text-box-1">
          <font-awesome-icon icon="fa-solid fa-gem" class="mb-4 text-4xl text-medium-green"/>
          <p class="text-3xl">{{$t("upcoming.images.second-img")}}</p>
        </div>
        <p class="test-p" :class="hovering1 ? 'opacity-100' : 'opacity-0'">{{$t("upcoming.images.second-des")}}
        </p>
      </div>
      <div class="flex items-center justify-center flex-col p-8 w-full lg:w-1/3 h-[387px] lg:h-[687px] bg-black test third-test relative" @mouseover="hovering2 = true" @mouseleave="hovering2 = false">
        <div class="text-box-1 flex-center-col">
          <font-awesome-icon icon="fa-solid fa-people-group" class="mb-4 text-4xl text-medium-green"/>
          <p class="text-3xl">{{$t("upcoming.images.third-img")}}</p>
        </div>
        <p class="test-p" :class="hovering2 ? 'opacity-100' : 'opacity-0'">{{$t("upcoming.images.third-des")}}</p>
      </div>
    </div>
    <div class=" flex items-center justify-around flex-col lg:flex-row lg:flex-wrap w-11/12 lg:w-9/12 mx-auto py-20 text-black">
      <div v-for="t in tour" :key="t.id" class="table-cell flex-col p-4 lg:p-8 mb-16 w-[350px] lg:w-[400px] min-h-full max-h-full  shadow-sm shadow-[#797979] border border-[#D5DFFF] rounded-lg">
        <div class="flex items-center justify-center flex-col w-full text-dark-green">
          <h3 class="text-xl font-bold">{{t.name}}</h3>
          <h3 class="text-xl font-light">{{t.date}}</h3>
          <div class="flex justify-center items-end my-6">
            <router-link :to="`/event/` + t.id" @click="scrollToTop" class="offer-btn">{{$t("upcoming.boxes.first.button")}}</router-link>
          </div>
        </div>
        <div class="flex flex-col w-full mt-8">
          <div class="w-full text-lg leading-snug tracking-wider text-start"></div>
          <div class="flex flex-col items-start w-full mt-4">
            <li v-for="(d,ind) in t.descriptions" :key="ind" class="mb-4 text-start tracking-wider text-xs lg:text-sm">
              <font-awesome-icon icon="fa-solid fa-check" class="text-medium-green mr-2"/>
              <span>{{d}}</span>
            </li>
          </div>
        </div>
      </div>


    </div>
    <FaqList/>
    <FooterSection/>
  </div>
</template>
<script>
import {isProxy, onMounted, reactive, ref, toRaw} from "vue";
import FooterSection from "@/components/FooterSection";
import FaqList from "@/components/FaqList";
import {useStore} from "vuex";
export default {
  components: {FooterSection,FaqList},
  setup() {
    function scrollToTop() {
      window.scrollTo(0,0);
    }
    const store = useStore();
    const tour = ref([]);
    onMounted(async () => {
      const locale = localStorage.getItem('locale');
      const lang = locale.startsWith('pl') ? 'Pl' : locale.startsWith('en') ? 'Eng' : 'Ger';
      await store.dispatch('posts/fetchPosts');
      store.getters['posts/getPosts'].map(post => {
        if (isProxy(post)){
          post = toRaw(post)
        }
        const title = post[`title${lang}`]
        const parts = title.split(",");
        const name = parts[0] || '';
        const date = parts[1] || '';
        const desc = [];
        const posts = post[`desc${lang}`];
        posts.forEach(p=>{
          desc.push(p);
        })
        const obj = {
          id: post.id,
          name: name,
          date: date,
          descriptions: desc
        };
        tour.value.push(obj);
      });
    })
    const tours = reactive([
      {
        name:'Enduro Cyprus - all levels',
        date:'12-16 March',
        des:'',
        point:[
          {
            pointName:'3 days of enduro training with an instructor'
          },
          {
            pointName:'Motorbike mechanics training conducted by our expert dirt bike mechanic'
          },
          {
            pointName:'Sightseeing the region of Limassol with a dedicated guide',
          },
          {
            pointName:'Archery tournament',
          },
          {
            pointName:'Photographer on site during rides to memorise your adventure on photos and videos',
          },
        ]
      },
      {
        name:'Enduro Cyprus - all levels',
        date:'7 - 11 May',
        des:'',
        point:[
          {
            pointName:'3 days of enduro training with an instructor'
          },
          {
            pointName:'Motorbike mechanics training conducted by our expert dirt bike mechanic'
          },
          {
            pointName:'Sightseeing the region of Limassol with a dedicated guide'
          },
          {
            pointName:'Archery tournament'
          },
          {
            pointName:'Photographer on site during rides to memorise your adventure on photos and videos'
          },
        ]
      }
    ])
    const hovering = ref(false);
    const hovering1 = ref(false);
    const hovering2 = ref(false);
    return {
      tours,hovering,hovering1,hovering2,scrollToTop,tour
    }
  }
}
</script>

<style scoped>
.bg-img {
  background-image: url("@/assets/upc.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  width:100%;
}
.dark-color {
  background-color:rgba(0,0,0,.6);
}
.title {
  color: transparent;
  -webkit-text-stroke:1.5px white;
  -moz-text-stroke: 1.5px white;
  text-stroke: 1.5px white;
}
ol,li {
  list-style: none;
}
.test {
  transition: height 0.3s ease;
  position: relative;
  cursor: pointer;
}

.test {
  position: relative;
  overflow: hidden;
}

.test img {
  position: relative;
  z-index: 1;
}

.test:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
.text-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  width: 100%;
  height: 70%;
  color:white;
  z-index: 3;
  margin: 0 auto;
}
.test-p {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right:0;
  bottom:0;
  z-index: 4;
  width: 90%;
  margin:0 auto;
  height:30%;
  font-size: 13px;
  text-align: center;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  transition:max-height 0.3s ease-in;
}
.first-test {
  background-image: url('@/assets/up1.jpg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.second-test {
  background-image: url('@/assets/up2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.third-test {
  background-image: url('@/assets/sharing.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width:768px){
  .test-p {
    width: 80%;
    margin:0 auto;
  }
}
.opacity-0 {
  opacity: 0;
  max-height: 0;
}

.opacity-100 {
  opacity: 1;
  height:80%;
  max-height: 100%;
}

</style>
