import axios from "axios";

const http = axios.create({
    baseURL: 'https://enduro-backend.herokuapp.com/',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://enduro-backend.herokuapp.com/'
    },
});



export default http;

