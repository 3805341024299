<template>
  <div v-if="show" :style="{  top: scrollValue }" class="absolute flex items-center justify-center inset-0 place-items-center bg-shadow z-20"
       @click.self="handleClose">
    <div
        class="fixed top-14 flex items-center justify-between flex-col px-4 lg:px-8 py-6 w-11/12 mt-6 lg:mt-10 lg:w-[700px] h-8/12 lg:h-[730px] bg-white max-h-full overflow-auto">
      <div class="flex items-start justify-start flex-col w-full">
        <div class="flex flex-col items-start w-full">
          <div class="flex items-center justify-between w-full">
            <h3 class="text-xl lg:text-3xl font-bold">{{ $t("contact.form.title-one") }}</h3>
            <button class="text-xl font-medium" @click="handleClose">X</button>
          </div>
          <p class="mt-3 text-xs lg:text-sm text-start text-gray-500">{{
              $t("contact.form.title-two")
            }}</p>
        </div>
        <form id="frm" class="flex flex-col items-start justify-start w-full mt-4"
              @submit.prevent="submitForm">
          <label class="text-xs lg:text-sm" for="fullname">{{ $t("contact.form.name") }}</label>
          <input id="fullname" class="px-4 py-1 rounded-md w-full text-sm border border-gray-400"
                 name="fullname" required
                 type="text"/>
          <label class="mt-3 text-xs lg:text-sm" for="phone">{{ $t("contact.form.phone") }}</label>
          <input id="phone" class="px-4 py-1 w-full rounded-md text-sm border border-gray-400"
                 name="phone" required
                 type="tel"/>
          <label class="mt-3 text-xs lg:text-sm" for="email">{{ $t("contact.form.mail") }}</label>
          <input id="email" class="px-4 py-1 w-full rounded-md text-sm border border-gray-400"
                 name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required
                 type="email"/>
          <label class="mt-3 text-xs lg:text-sm" for="topic">{{ $t("contact.form.topic") }}</label>
          <select id="trip-select" class="px-4 py-1 rounded-md w-full text-sm border border-gray-400" v-model="tripVal" name="topic"
                  @change="handleTripChange">
            <option selected disabled  value="1">{{ $t("contact.form.choose") }}</option>
            <option v-for="(event, index) in events" :value="event.id" :key="index + 2">{{ event.title }}</option>
            <option value="98">{{ $t("contact.form.book-three") }}</option>
            <option value="99">{{ $t("contact.form.book-other") }}</option>
          </select>
          <div v-if="tripOne" class="w-full flex items-start flex-col">
            <label class="mt-3 text-xs lg:text-sm" for="riding-experience">{{
                $t("contact.form.exp")
              }}</label>
            <select id="experience-select"
                    class="px-4 py-1 rounded-md w-full text-sm border border-gray-400"
                    name="riding-experience">
              <option disabled selected value="1">{{ $t("contact.form.choose") }}</option>
              <option value="2">{{ $t("contact.form.trip-one.book-one") }}</option>
              <option value="3">{{ $t("contact.form.trip-one.book-two") }}</option>
              <option value="4">{{ $t("contact.form.trip-one.book-three") }}</option>
              <option value="5">{{ $t("contact.form.trip-one.book-four") }}</option>
              <option value="6">{{ $t("contact.form.trip-one.book-five") }}</option>
            </select>
            <label class="mt-3 text-xs lg:text-sm" for="package">{{
                $t("contact.form.trip-one.packages")
              }}</label>
            <select id="package-select"
                    class="px-4 py-1 rounded-md w-full text-sm border border-gray-400"
                    name="package">
              <option disabled selected value="1">{{ $t("contact.form.choose") }}</option>
              <option value="2">{{ $t("contact.form.trip-one.pck-one") }}</option>
              <option value="3">{{ $t("contact.form.trip-one.pck-two") }}</option>
              <option value="4">{{ $t("contact.form.trip-one.pck-three") }}</option>
            </select>
          </div>
          <div v-if="tripPrivate" class="w-full flex items-start flex-col">
            <div class="flex">
              <label class="mt-3 text-xs lg:text-sm" for="start-date">{{
                  $t("contact.form.priv-trip.title")
                }}</label>
              <input id="start-date"
                     class="ml-4 mt-3  px-4 py-2 rounded-md text-xs lg:text-sm border border-gray-400"
                     name="start-date"
                     type="date"/>
            </div>
            <label class="mt-3 text-xs lg:text-sm"
                   for="riding-days">{{ $t("contact.form.priv-trip.book-title") }}</label>
            <select id="riding-days"
                    class="px-4 py-1 rounded-md w-full text-sm border border-gray-400"
                    name="riding-days">
              <option disabled selected value="1">{{ $t("contact.form.choose") }}</option>
              <option value="2">{{ $t("contact.form.priv-trip.book-one") }}</option>
              <option value="3">{{ $t("contact.form.priv-trip.book-three") }}</option>
              <option value="4">{{ $t("contact.form.priv-trip.book-four") }}</option>
              <option value="5">{{ $t("contact.form.priv-trip.book-five") }}</option>
            </select>
            <div class="w-full flex flex-col"></div>
            <label class="mt-3 text-xs lg:text-sm"
                   for="trip-length">{{ $t("contact.form.priv-trip.length-trip") }}</label>
            <input id="trip-length"
                   class="px-4 rounded-md py-1 w-full text-sm border border-gray-400"
                   name="trip-length"
                   type="text"/>
            <label class="mt-3 text-xs lg:text-sm"
                   for="riding-experience">{{ $t("contact.form.priv-trip.riding-exp") }}</label>
            <select id="riding-experience"
                    class="px-4 rounded-md py-1 w-full text-sm border border-gray-400"
                    name="riding-experience">
              <option disabled selected value="1">{{ $t("contact.form.choose") }}</option>
              <option value="2">{{ $t("contact.form.priv-trip.b1") }}</option>
              <option value="3"> {{ $t("contact.form.priv-trip.b2") }}</option>
              <option value="4">{{ $t("contact.form.priv-trip.b3") }}</option>
              <option value="5">{{ $t("contact.form.priv-trip.b4") }}</option>
              <option value="6">{{ $t("contact.form.priv-trip.b5") }}</option>
              <option value="7">{{ $t("contact.form.priv-trip.b6") }}</option>
            </select>
            <label class="mt-3 text-xs lg:text-sm"
                   for="riding-experience-details">{{ $t("contact.form.priv-trip.b7") }}</label>
            <input id="riding-experience-details"
                   class="px-4 py-1 rounded-md w-full text-sm border border-gray-400"
                   name="riding-experience-details"
                   type="text"/>
            <label class="mt-3 text-xs lg:text-sm" for="package-select">{{
                $t("contact.form.priv-trip.b8")
              }}</label>
            <select id="package-select"
                    class="px-4 py-1 w-full rounded-md text-sm border border-gray-400"
                    name="package-select">
              <option disabled selected value="1">{{ $t("contact.form.choose") }}</option>
              <option value="2">{{ $t("contact.form.priv-trip.b9") }}</option>
              <option value="3">{{ $t("contact.form.priv-trip.b10") }}</option>
              <option value="4">{{ $t("contact.form.priv-trip.b11") }}</option>
            </select>
            <label class="mt-3 text-xs lg:text-sm"
                   for="additional_info_input">{{ $t("contact.form.priv-trip.b12") }}</label>
            <input class="px-4 py-1 w-full text-sm rounded-md border border-gray-400"
                   name="additional_info_input" type="text"/>
          </div>
          <label class="mt-3 text-xs lg:text-sm" for="message_input">{{
              $t("contact.form.priv-trip.b13")
            }}</label>
          <textarea class="px-4 py-2 w-full text-sm rounded-md max-h-44 border border-gray-400"
                    name="message_input" required
                    type="text"/>
          <div class="flex items-end justify-end w-full mt-10">

            <span v-if="showError">{{ errorMessage }}</span>
            <button :disabled="isSending" class="offer-btn" type="submit">
              <div v-if="isSending">
                <div v-if="isSending" class="fingerprint-spinner">
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                  <div class="spinner-ring"></div>
                </div>
              </div>
              <div v-if="!isSending">{{ $t("contact.form.priv-trip.b14") }}</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="showAlert" class="alert">
    {{ $t("contact.send-mail") }}
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";
import EmailService from "@/services/email-service";
import {useStore} from "vuex";

export default {
  name: "ContactModal",
  props: {
    choosenTrip: {
      type: String,
      default: ""
    },
    show: {
      type: Boolean,
      default: false
    }

  },
  setup(props, {emit}) {
    const tripOne = ref(false);
    const scrollValue = ref("px");
    const tripPrivate = ref(false);
    const tripVal = ref(1);
    const events = ref([]);
    const store = useStore();

    async function load() {
      await store.dispatch('posts/fetchPosts');
      const locale = localStorage.getItem('locale');
      const lang = locale.startsWith('pl') ? 'Pl' : locale.startsWith('en') ? 'Eng' : 'Ger';

      events.value = store.getters['posts/getPosts'].map(post => {
        return {
          id: post.id,
          title: post[`title${lang}`]
        };
      });
    }

    onMounted(() => {
      load();
    });
    const isSending = ref(false);
    const handleScroll = () => {
      scrollValue.value = window.scrollY + "px";
    }
    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    function handleClose() {
      emit('close');
    }

    const handleTripChange = (event) => {
      const value = event.target.value
      tripOne.value = value !== '98' && value !== '99';
      tripPrivate.value = value === '98'
    }

    function submitForm() {
      isSending.value = true;
      const formEl = document.getElementById('frm');
      const formData = new FormData(formEl);
      let message = "";
      let fullName = "";
      let email = "";
      for (let [key, val] of formData.entries()) {
        if (key === 'fullname') {
          fullName = val;
          continue;
        }
        if (key === 'email') {
          email = val;
          continue;
        }
        const label = findLabel(key);
        const selectVal = findSelectValue(key, val);
        const mesVal = selectVal == null ? val : selectVal;
        message += label + ": " + mesVal + " <br>";
      }

      return sendEmail(email, fullName, "New message from " + fullName, message, function () {
        if (typeof window !== 'undefined' && typeof fbq === 'function') {
          window.fbq('track', 'Contact');
        }
        for (const key of formData.keys()) {

          const input = formEl.querySelector(`[name="${key}"]`);
          if (input.tagName === "SELECT") {
            input.selectedIndex = 1;
          } else {
            input.value = '';
          }
        }
        tripVal.value = 1;
        tripOne.value = false;
        tripPrivate.value = false;
      });
    }

    function getSelectByName(name) {
      return document.querySelector(`select[name="${name}"]`);
    }

    function findSelectValue(selectVal, keyVal) {
      const select = getSelectByName(selectVal);
      if (select == null) {
        return null;
      }
      const selectedOption = select.querySelector(`option[value="${keyVal}"]`);
      if (selectedOption == null) {
        return null;
      }
      return selectedOption.innerText;
    }

    function findLabel(inputId) {
      const label = document.querySelector(`label[for="${inputId}"]`);
      if (label != null) {
        return label.innerText;
      }
      return "NULL";
    }

    const showError = ref(false);
    const errorMessage = ref(
        "There was an error while sending. Drop us an email at contact@endurogoats.com");

    function sendEmail(email, name, subject, content, cleanCallback) {
      return EmailService.sendEmail(email, name, subject, content)
      .then(res => {

        if (res.status && res.status != 200) {
          showError.value = true;
          isSending.value = false;
        } else {
          cleanCallback();
          isSending.value = false;
          showError.value = false;
          showAlert.value = true;
          setTimeout(() => {
            showAlert.value = false;
          }, 2000);
        }
      }, err => {
        if (err) {
          showError.value = true;
        }
        isSending.value = false;

      });

    }

    const showAlert = ref(false);

    return {
      tripOne,
      tripPrivate,
      handleTripChange,
      sendEmail,
      showError,
      errorMessage,
      submitForm,
      tripVal,
      handleClose,
      scrollValue,
      showAlert,
      isSending,
      events
    }
  }

}
</script>

<style scoped>
.fingerprint-spinner, .fingerprint-spinner * {
  box-sizing: border-box;
}

.fingerprint-spinner {
  height: 64px;
  width: 64px;
  padding: 2px;
  overflow: hidden;
  position: relative;
}

.fingerprint-spinner .spinner-ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #ff1d5e;
  animation: fingerprint-spinner-animation 1500ms cubic-bezier(0.680, -0.750, 0.265, 1.750) infinite forwards;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.fingerprint-spinner .spinner-ring:nth-child(1) {
  height: calc(60px / 9 + 0 * 60px / 9);
  width: calc(60px / 9 + 0 * 60px / 9);
  animation-delay: calc(50ms * 1);
}

.fingerprint-spinner .spinner-ring:nth-child(2) {
  height: calc(60px / 9 + 1 * 60px / 9);
  width: calc(60px / 9 + 1 * 60px / 9);
  animation-delay: calc(50ms * 2);
}

.fingerprint-spinner .spinner-ring:nth-child(3) {
  height: calc(60px / 9 + 2 * 60px / 9);
  width: calc(60px / 9 + 2 * 60px / 9);
  animation-delay: calc(50ms * 3);
}

.fingerprint-spinner .spinner-ring:nth-child(4) {
  height: calc(60px / 9 + 3 * 60px / 9);
  width: calc(60px / 9 + 3 * 60px / 9);
  animation-delay: calc(50ms * 4);
}

.fingerprint-spinner .spinner-ring:nth-child(5) {
  height: calc(60px / 9 + 4 * 60px / 9);
  width: calc(60px / 9 + 4 * 60px / 9);
  animation-delay: calc(50ms * 5);
}

.fingerprint-spinner .spinner-ring:nth-child(6) {
  height: calc(60px / 9 + 5 * 60px / 9);
  width: calc(60px / 9 + 5 * 60px / 9);
  animation-delay: calc(50ms * 6);
}

.fingerprint-spinner .spinner-ring:nth-child(7) {
  height: calc(60px / 9 + 6 * 60px / 9);
  width: calc(60px / 9 + 6 * 60px / 9);
  animation-delay: calc(50ms * 7);
}

.fingerprint-spinner .spinner-ring:nth-child(8) {
  height: calc(60px / 9 + 7 * 60px / 9);
  width: calc(60px / 9 + 7 * 60px / 9);
  animation-delay: calc(50ms * 8);
}

.fingerprint-spinner .spinner-ring:nth-child(9) {
  height: calc(60px / 9 + 8 * 60px / 9);
  width: calc(60px / 9 + 8 * 60px / 9);
  animation-delay: calc(50ms * 9);
}

@keyframes fingerprint-spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}

.bg-shadow {
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
}

input:focus, select:focus, textarea:focus {
  outline: none;
}

input, select, textarea {
  background: transparent;
}

.alert {
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  width: 60%;
  margin: 0 auto;
  height: 50px;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  border-radius: 15px;
  z-index: 999;
  animation: slide-down 0.5s ease-out;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}
</style>
