<template>
  <div class="flex-center-col w-full h-full" data-aos="fade-down">
    <div class="flex-center-col w-full h-[80vh] relative event-bg">
      <div class="absolute inset-0 bg-black opacity-70"></div>
      <div class="absolute inset-0 flex-center w-7/12 3xl:w-6/12 mx-auto h-full mt-10">
        <h2 class="uppercase text-6xl lg:text-8xl leading-snug font-bold line text-medium-white tracking-wider">{{$t('events.private.title')}}</h2>
      </div>
    </div>
    <div class="flex-center-col w-11/12 lg:w-8/12 mx-auto my-16 lg:my-32">
      <h2 class="mb-14 text-2xl lg:text-3xl font-bold tracking-wider text-gray">{{$t('events.private.title')}}</h2>
      <p class="text-lg lg:text-xl tracking-wider line-h text-gray">{{$t('events.private.desc')}}</p>
      <p class="my-10 text-lg lg:text-xl tracking-wide line-h text-gray">{{$t('events.private.letus')}}</p>
      <button class="offer-btn" @click="openForm=!openForm">{{$t('events.private.book')}}</button>
    </div>
    <div class="flex-center-col w-11/12 lg:w-8/12 mx-auto my-20">
      <h2 class="mb-6 lg:mb-8 text-2xl lg:text-3xl font-bold tracking-wider text-gray uppercase">{{$t('events.questions')}}</h2>
      <p class="text-lg lg:text-xl" v-html="parseVariables('events.que-answer')">
      </p>
    </div>
    <FooterSection/>
  </div>
  <ContactModal :show="openForm" @close="openForm = !openForm"/>
</template>

<script>
import FooterSection from "@/components/FooterSection";
import {ref} from "vue";
import ContactModal from "@/components/ContactModal.vue";
import {useI18n} from "vue-i18n";
export default {
  components: {FooterSection,ContactModal},
  setup(){
    const openForm = ref(false);
    const {t} = useI18n()
    function parseVariables(key) {
      const val = t(key);
      const newVal = val.replaceAll("%faq%", '<a href="/faq" class="font-medium" @click="scrollToTop">FAQ</router-link>')
          .replaceAll("%contact%", '<a href="/contact" class="font-medium"  @click="scrollToTop">drop us a line!</router-link>')
      return newVal;
    }
    return {openForm,parseVariables}
  }
}
</script>

<style scoped>
.line {
  color: transparent;
  -webkit-text-stroke:1.5px white;
  -moz-text-stroke: 1.5px white;
  text-stroke: 1.5px white;
}
.event-bg {
  background-image: url('@/assets/ev1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.line-h{
  line-height: 276%;
}
</style>
