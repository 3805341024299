<template>
  <div class="flex flex-col items-center justify-center w-7/12 mx-auto py-32">
    <form class="p-5 bg-gray-300 rounded-lg flex items-center flex-col justify-between gap-4"
          @submit.prevent="submitLogin">
      <h2 class="">Login: admin, Hasło: Zayn@dmin123!</h2>
      <label for="email">Login</label>
      <input
          v-model="login"
          class="form-control border border-gray rounded-xl p-2"
          required
          type="text"
      />
      <div>
        <label for="password">Password</label>
        <a class="cursor-pointer" @click="hidePassword = !hidePassword">
          <font-awesome-icon :icon="passwordShowIcon" class="ml-2 text-dark-green text-sm"/>
        </a>
      </div>
      <input
          v-model="password"
          :type="passwordShowType"
          class="form-control border border-gray rounded-xl p-2"
          name="password"
          required
      />
      <button id="login_button" class="mt-4 btn-pers border border-gray rounded-xl px-8 py-2" type="submit">
        Login
      </button>
    </form>
    <div v-if="showError">
      <p>Twoje dane logowania, nie sa poprawne! Sprobuj ponownie</p>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import LoginService from "@/services/login-service";
import {useRouter} from "vue-router";

export default {
  name: "LoginView",
  setup() {
    const login = ref(null);
    const password = ref(null);
    const showError = ref(false);
    const hidePassword = ref(true);
    const passwordShowIcon = computed(() => hidePassword.value ? "fa-eye" : "fa-eye-slash");
    const passwordShowType = computed(() => hidePassword.value ? "password" : "text");
    const router = useRouter()
    onMounted(()=>{
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        router.push('/panel');
      }
    })
    function submitLogin() {
      return LoginService.loginUser({
        username: login.value,
        password: password.value
      }).then(res => {
        if (res.status && res.status != 200) {
          showError.value = true;
        } else {
          showError.value = false;
          login.value = "";
          password.value = "";
          router.push('/panel');
        }
      }, err => {
        if (err)
          showError.value = true;

      })

    }

    return {
      login, password, passwordShowType, passwordShowIcon, submitLogin, hidePassword, showError
    }
  }
}
</script>

<style scoped>
input:focus {
  outline: none;
}
</style>
