import httpPost from './http-post-service';
import http from './service-base';

class ImageServices {
  uploadFile(file, percentCallback) {
    const formData = new FormData();
    formData.append('file', file);
    return httpPost.post('/api/public/images/upload', formData, {
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total)
        percentCallback(percentCompleted)
      }
    });
  }

  getImageUrl(id) {
    return http.get(`api/public/images/images/` + id,
        {responseType: 'blob'});
  }

  deleteImage(id) {
    return httpPost.delete(`api/public/images/delete/` + id,);
  }
}

export default new ImageServices();
