<template>
  <div class="p-12  m-3 border border-gray-two border-solid">
    <h1 class="mb-2">Create packages</h1>
    <div v-for="(d,dIndex) in packages" :key="d.id" class="mt-10 pb-6 border-b border-gray-two border-solid">
      <v-text-field v-model="d.title" clearable label="Tytuł" placeholder="np. Basic - 780 EUR"
                    variant="solo"/>
      <v-text-field v-model="d.desc" clearable label="Opis" placeholder="np. Chcesz po prostu pojeździć"
                    variant="solo"/>
      <div class="flex flex-col p-16">
        <div v-for="(i,pIndex) in d.packagesInclude" :key="i.id">
          <div class="p-2 basis-1/4  flex-1">
            <v-text-field v-model="i.packageTitle" clearable label="Package title" placeholder="np. Zakwaterowanie "
                          variant="solo"/>
            <v-text-field v-model="i.packageIcon" clearable label="Package icon" placeholder="ikona z font-awesome "
                          variant="solo"/>
            <div v-for="(inc,index) in i.includes" :key="index" class="flex flex-row justify-center items-center gap-4">
              <v-text-field v-model="inc.packageInfo" :label="`Description #${index}`" clearable
                            placeholder="np. Paliwo motocyklowe " variant="solo"/>
              <label for="checkbox">Cross?</label><br>
              <input id="checkbox" v-model="inc.tick" type="checkbox"/>
              <v-btn color="secondary" @click="removePackageDescription(i,index)">Remove</v-btn>
            </div>
            <v-btn class="mr-2" color="secondary" @click="addPackageDescription(i)">Add Desc</v-btn>
            <v-btn color="primary" @click="removePackageInclude(d,pIndex)">Remove Package Info</v-btn>
            <v-btn
                v-if="pIndex !== 0"
                class="ml-2"
                color="primary"
                @click="movePackageIncludeUp(d, pIndex)"
            >
              Move Up
            </v-btn>
            <v-btn
                v-if="pIndex !== d.packagesInclude.length - 1"
                class="ml-2"
                color="primary"
                @click="movePackageIncludeDown(d, pIndex)"
            >
              Move Down
            </v-btn>
          </div>

        </div>
      </div>
      <div class="flex items-end justify-end w-full">
        <v-btn class="mr-2" color="secondary" @click="addPackageInclude(d)">Add Package Info</v-btn>
        <v-btn color="primary" @click="removePackage(d,dIndex)">Remove Package</v-btn>
      </div>
    </div>
    <div class="flex justify-center align-center gap-4 mt-4">
      <v-btn color="secondary" size="large" @click="addPackage">Add Package
        <v-icon color="success" icon="mdi-vuetify"></v-icon>
      </v-btn>

    </div>
  </div>
</template>

<script>
import { ref, watch} from 'vue'

export default {
  name: "PackagesCreatorComponent",
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, {emit}) {


    const packages = ref(props.modelValue)
    watch(packages, (newVal) => {
      emit('update:modelValue', newVal);

    });
    watch(() => props.modelValue, (newVal) => {
      packages.value = newVal;
    });
    function addPackage() {
      const newPackage = {
        desc: "",
        title: "",
        packagesInclude: [
          {
            id: 0,
            packageTitle: "",
            packageIcon: "",
            includes: [
              {
                id: 0,
                packageInfo: "",
                tick: false
              }
            ],
          }
        ]
      }
      packages.value.push(newPackage)
    }

    function addPackageDescription( include) {
      const newIncludes = {
        packageInfo: "",
        tick: false

      }
      include.includes.push(newIncludes)
    }

    function removePackageDescription(include, index) {
      include.includes.splice(index, 1);
    }

    function removePackage(index) {
      packages.value.splice(index, 1)
    }

    function addPackageInclude(data) {
      const newPackageInclude = {
        packageTitle: "",
        packageIcon: "",
        includes: [
          {
            id: 0,
            packageInfo: "",
            tick: false
          }
        ],
      }
     data.packagesInclude.push(newPackageInclude)
    }
    function movePackageIncludeUp(data, packageIncludeIndex) {
      if (packageIncludeIndex > 0) {
        const temp = data.packagesInclude[packageIncludeIndex];
        data.packagesInclude.splice(packageIncludeIndex, 1);
        data.packagesInclude.splice(packageIncludeIndex - 1, 0, temp);
      }
    }

    function movePackageIncludeDown(data, packageIncludeIndex) {
      if (packageIncludeIndex < data.packagesInclude.length - 1) {
        const temp = data.packagesInclude[packageIncludeIndex];
        data.packagesInclude.splice(packageIncludeIndex, 1);
        data.packagesInclude.splice(packageIncludeIndex + 1, 0, temp);
      }
    }
    function removePackageInclude(data, packageIncludeIndex) {
      data.packagesInclude.splice(packageIncludeIndex, 1)
    }

    return {
      packages,
      addPackage,
      removePackage,
      addPackageInclude,
      removePackageInclude, addPackageDescription, removePackageDescription,
      movePackageIncludeUp,
      movePackageIncludeDown,
    }
  }
}
</script>
