import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UpcomingTours from '../views/UpcomingTours'
import FaqViews from '../views/FaqViews'
import ContactView from '../views/ContactView'
import PrivacyPolicy from '../views/PrivacyPolicy'
import GalleryView from '../views/GalleryView'
import LoginView from "@/views/LoginView.vue";
import AdminPanel from "@/views/adminpanel/AdminPanel.vue";
import NewEvent from "@/views/adminpanel/NewEvent.vue";
import PrivateTrip from "@/views/adminpanel/PrivateTrip.vue";
import PostCreator from "@/views/adminpanel/new/panel/PostCreator.vue";
import NewEventGenerator from "@/views/adminpanel/new/NewEventGenerator.vue";
import ImprintView from "@/views/ImprintView";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/event/:id',
    name: 'event',
    component: NewEventGenerator,
    props: true,
  },

  {
    path: '/upcoming',
    name: 'UpcomingTours',
    component: UpcomingTours
  },
  {
    path: '/private-trip',
    name: 'PrivateTrip',
    component: PrivateTrip
  },
  {
    path: '/newevent',
    name: 'NewEvent',
    component: NewEvent
  },
  {
    path: '/gallery',
    name: 'GalleryView',
    component: GalleryView
  },
  {
    path: '/faq',
    name: 'FaqViews',
    component: FaqViews
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/imprint',
    name: 'ImprintView',
    component: ImprintView
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/admin',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/panel',
    name: 'AdminPanel',
    component: AdminPanel
  },

  {
    path: '/post/:id?',
    name: 'PostCreator',
    component: PostCreator,
    props: true,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
