<template>
  <div class="w-full min-h-96 bg-gray py-14 lg:py-8 text-white">
    <div class="flex flex-col w-full lg:w-8/12 mx-auto px-4 lg:px-0">
      <div class="flex flex-cb flex-col lg:flex-row lg:items-start">
        <div class="flex-cs lg:items-start flex-col lg:w-1/2">
          <img src="@/assets/logo2.png" alt="logo" class="w-16"/>
          <p class="mt-1 lg:mt-3 mb-8 lg:mb-0 text-sm text-medium-white hidden">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit vero</p>
        </div>
        <div class="flex items-start justify-between w-full md:w-4/6 lg:w-5/12 mt-4 md:mt-0">

          <div class="footer-pages text-medium-white text-left pr-12 md:pr-0">
            <p class="font-bold text-medium-green">{{$t("footer.trips")}}</p>
            <div v-for="(event, index) in events" :key="index" class="my-1 py-1">
              <router-link class="my-2" :to="`/event/` + event.id" v-html="formatTitle(event.title)"></router-link>
            </div>
        <router-link to="/private-trip" class="mt-2">{{$t("footer.priv-trip")}}</router-link>
          </div>
          <div class="footer-pages text-medium-white text-left">
            <p class="font-bold text-medium-green">{{$t("footer.contact")}}</p>
            <router-link to="/contact" class="my-2">{{$t("footer.book-a-tour")}}</router-link>
            <router-link to="/faq">FAQ</router-link>
            <router-link to="/contact" class="my-2">{{$t("footer.form")}}</router-link>
            <router-link to="/privacy" class="mb-2">{{$t("footer.privacy")}}</router-link>
            <router-link to="/imprint" class="">Imprint</router-link>
          </div>
        </div>
      </div>
      <div class="flex-cb flex-col lg:flex-row mt-8 lg:mt-8 lg:pt-4 w-full border-t border-medium-white text-medium-white">
        <p class="text-start text-sm mt-4">&copy; {{ currentYear }} All Rights Reserved | <a href="https://zayncode.com" class="text-medium-green">ZaynCode</a></p>
        <div class="flex-sc mt-2">
          <a href="https://www.facebook.com/EnduroGoatsTravel" class="footer-social"><font-awesome-icon icon="fa-brands fa-facebook" /></a>
          <a href="https://www.instagram.com/endurogoatstravel/?fbclid=IwAR32d1Rsv8h-RW8RxeWF_3siGP1mZP9UY9BhKAxjjJvHRCoHuOXSJMJo3hY" class="footer-social ml-4"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
          <a href="https://www.youtube.com/channel/UCef-H6TZNQgCD6wtB5Q5k1w" class="footer-social ml-4"><font-awesome-icon icon="fa-brands fa-youtube" /></a>
          <a href="https://www.tiktok.com/@endurogoatstravel" class="footer-social ml-4"><font-awesome-icon icon="fa-brands fa-tiktok" /></a>
          <a href="https://www.instagram.com/liagoesaround/" class=" hidden"><img src="@/assets/social.png" class="text-medium-green"/></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
  setup() {
    const currentYear = ref(new Date().getFullYear());
    const events = ref([]);
    const store = useStore();
    onMounted(async () => {
      load()
    });
    function formatTitle(title) {
      const boldWords = ['Enduro', 'Muay Thai']
      let formattedTitle = title
      boldWords.forEach(word => {
        formattedTitle = formattedTitle.replace(
            new RegExp(`(${word})`, 'g'),
            '<strong>$1</strong>'
        )
      })
      return formattedTitle
    }
    async function load() {
      try {
        await store.dispatch('posts/fetchPosts');
        const locale = localStorage.getItem('locale');
        const lang = locale.startsWith('pl') ? 'Pl' : locale.startsWith('en') ? 'Eng' : 'Ger';

        events.value = store.getters['posts/getPosts'].map(post => {
          return {
            id: post.id,
            title: post[`title${lang}`]
          };
        });

      } catch (error) {
        console.log('All posts error:', error);
      }
    }
    return {
      currentYear,load,events,formatTitle
    }
  }

}
</script>

<style scoped>

</style>
