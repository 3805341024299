<template>
  <div class="h-full w-screen pt-52 text-gray">
    <div class="flex-center-col w-11/12 mx-auto">
      <div class="flex-center-col w-full">
        <h2 class="text-xl font-bold tracking-wider">Choose template</h2>
        <div class="flex items-center justify-center w-6/12 mt-4 mx-auto">
          <button class="flex-center" @click="temp1 = true"><img src="@/assets/temp1.png" alt="Event" class="w-1/2 border border-medium-white shadow-sm shadow-gray hover:shadow-lg hover:shadow-gray transition-all"/></button>
        </div>
      </div>
      <div class="flex-center-col mt-8 w-full" v-if="temp1">
        <div class="flex items-start flex-col w-6/12 mx-auto">
          <label>Header Image</label>
          <input type="image" class="w-full"/>
          <label class="mt-4">Header Image Text</label>
          <input type="text" class="w-full"/>
          <label class="mt-4">Header 1</label>
          <input type="text" class="w-full"/>
          <label class="mt-4">Description 1</label>
          <input type="text" class="w-full"/>
          <label class="mt-4">Timeline 1</label>
          <input type="text" class="w-full"/>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
import {ref} from "vue";

export default {
  setup(){
    const temp1 = ref(true)
    return{
      temp1
    }
  }
}
</script>

<style scoped>
input {
  padding:5px 15px;
  border:1px solid black;
  border-radius: 5px;
}
inpu:focus {
  outline: none;
}
</style>

