<template>
  <div class="w-full pb-20 flex flex-col text-black px-4 lg:px-0">
    <div class="flex items-center flex-col lg:flex-row w-full lg:h-[450px]">
      <div class="lg:w-1/2 h-full relative">
        <img src="@/assets/last.jpg" alt="Enduro image" class="bg-cover bg-no-repeat bg-center w-full h-full rounded-xl lg:rounded-none lg:rounded-r-3xl hidden lg:flex">
        <div class="absolute inset-0 bg-black opacity-10 rounded-xl lg:rounded-none lg:rounded-r-3xl"></div>
        <img src="@/assets/tailored.jpg" alt="Enduro image" class="bg-cover bg-no-repeat bg-center w-full h-full rounded-xl lg:rounded-none lg:rounded-r-3xl lg:hidden">


        <div class="hidden absolute inset-0 bg-black opacity-30 rounded-xl lg:rounded-none lg:rounded-r-3xl"></div>
      </div>
      <div class="offer-box lg:w-1/2 lg:pr-40 lg:pl-4">
        <h4 class="offer-header text-start">{{$t("offer.offer1.header")}}</h4>
        <p class="offer-des">{{$t("offer.offer1.desc")}}</p>
        <router-link to="/upcoming" @click="scrollToTop" class="offer-btn">{{$t("offer.offer1.book")}}</router-link>
      </div>
    </div>
    <div class="flex items-center flex-col lg:flex-row w-full lg:h-[450px]">
      <div class="lg:w-1/2 h-full lg:hidden">
        <img src="@/assets/exploring.jpg" alt="Enduro image" class="w-full h-full rounded-xl">
      </div>
      <div class="offer-box lg:w-1/2 lg:pl-24 lg:pr-4">
        <h4 class="offer-header text-start">{{$t("offer.offer2.header")}}</h4>
        <p class="offer-des">{{$t("offer.offer2.desc")}}</p>
        <div class="flex-center lg:items-end lg:justify-end w-full">
          <router-link to="/upcoming" @click="scrollToTop" class="offer-btn shadow-md shadow-gray-400">{{$t("offer.offer1.book")}}</router-link>
        </div>
      </div>
      <div class="w-1/2 h-full hidden lg:flex">
        <img src="@/assets/homeimages/explore.jpg" alt="Enduro image" class="w-full h-full rounded-xl lg:rounded-none lg:rounded-l-3xl">
      </div>
    </div>
    <div class="flex items-center flex-col lg:flex-row w-full lg:h-[450px]">
      <div class="lg:w-1/2 h-full">
        <img src="@/assets/all.jpg" alt="Enduro image" class="w-full h-full rounded-xl lg:rounded-none lg:rounded-r-3xl lg:hidden">
        <img src="@/assets/homeimages/allin.jpg" alt="Enduro image" class="w-full h-full rounded-xl lg:rounded-none lg:rounded-r-3xl hidden lg:flex">
      </div>
      <div class="offer-box lg:w-1/2 lg:pr-40 lg:pl-4">
        <h4 class="offer-header text-start">{{$t("offer.offer3.header")}}</h4>
        <p class="offer-des">{{$t("offer.offer3.desc")}}</p>
        <router-link to="/upcoming" @click="scrollToTop" class="offer-btn shadow-md shadow-gray-400">{{$t("offer.offer3.book")}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup(){
    function scrollToTop() {
      window.scrollTo(0,0);
    }
    return{
      scrollToTop
    }
  }
}
</script>

<style scoped>

</style>
