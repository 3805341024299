<template>
  <div class="flex-center-col w-full h-full" data-aos="fade-down">
    <div class="flex-center-col w-full h-[80vh] relative event-bg" :style="{ backgroundImage: `url(${photos[0]})` }">

      <div class="absolute inset-0 bg-black opacity-70"></div>
      <div class="absolute inset-0 flex-center w-7/12 3xl:w-6/12 mx-auto h-full mt-10">
        <h2 class="uppercase text-4xl lg:text-8xl leading-snug font-bold line text-medium-white tracking-wider">
          {{ data.title }}</h2>
      </div>
    </div>
    <div class="flex-center-col w-11/12 lg:w-8/12 mx-auto my-32">
      <h2 class="mb-14 text-2xl lg:text-3xl font-bold tracking-wider text-gray">{{ data.title }}</h2>
      <p class="text-lg lg:text-xl tracking-wider line-h text-gray" v-html="data.description"></p>
    </div>
    <div v-if="data.dates.length>0" class="flex-center-col w-full">
      <h2 class=" text-2xl lg:text-3xl font-bold tracking-wider text-gray uppercase">{{ $t('events.2.trip') }}</h2>
      <p class="mt-4 text-center tracking-wide leading-tight mb-24">{{ $t('events.trip-desc') }}</p>

      <div class="flex-center-col w-full">
        <div class="flex w-full h-1 bg-medium-green">
          <div class="flex items-center  justify-between w-9/12 mx-auto">
            <div
                v-for="date in data.dates"
                :key="date"
                :class="[isSelected(date)? 'w-6 hover:h-6 lg:h-10 lg:w-10 bg-medium-green selected-date' : '']"
                class="flex-center-col w-4 lg:w-6 h-4 lg:h-6 hover:w-6 hover:h-6 lg:hover:h-10 lg:hover:w-10 lg:focus:h-10 lg:focus:w-10 p-2 bg-dark-green hover:bg-medium-green focus:bg-medium-green rounded-full transition-all"
                @click="changeSelectedDate(date)">

              <p class="mt-14 w-24 lg:w-44 text-xs lg:text-base font-medium lg:font-bold text-gray">{{
                  date.data
                }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
          class="flex items-center lg:items-start lg:justify-between flex-col md:flex-row flex-wrap w-11/12 lg:w-7/12 xl:w-7/12 mx-auto mt-10 lg:mt-32">
          <div v-for="di in selectedDateInfo" :key="di" class="flex items-start mt-8 w-full md:w-1/2 min-h-24 text-gray">
          <div class="w-full md:w-8/12 lg:w-4/12 mr-4 ">
            <p class="text-start uppercase font-bold tracking-wide">{{ di.timeFrom }}{{ di.timeTo }}</p>
          </div>
          <div class="flex items-start justify-start flex-col text-start w-72 ">
            <h3 class="uppercase font-bold tracking-wide">{{ di.timeTitle }}</h3>
            <p class="mt-4 text-start tracking-wide leading-tight">{{ di.timeDescription }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="data.packages.length>0">
    <div  v-for="packs in data.packages" :key="packs">
      <OfferComponent :data="packs" @openContact="contactFormOpen = !contactFormOpen"/>
    </div>
    <div v-if="photos.length>0" class="w-11/12 lg:w-9/12 mx-auto flex-center mt-14">
      <div class="carousel">
        <div class="carousel__photo">
          <img ref="photo" :src="currentPhoto" alt="Current Photo" class="e" data-aos="fade-right"/>
        </div>
      </div>
    </div>
    </div>
    <div class="flex-center-col w-11/12 lg:w-8/12 mx-auto my-20">
      <h2 class="mb-6 lg:mb-8 text-2xl lg:text-3xl font-bold tracking-wider text-gray uppercase">
        {{ $t('events.questions') }}</h2>
      <p class="text-lg lg:text-xl" v-html="parseVariables('events.que-answer')">
      </p>
    </div>
    <FooterSection/>
  </div>
  <ContactModal :show="contactFormOpen" @close="contactFormOpen = !contactFormOpen"/>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import OfferComponent from "@/views/adminpanel/new/OfferComponent.vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import ContactModal from "@/components/ContactModal";
import FooterSection from "@/components/FooterSection";
export default {
  name: "NewEventGenerator",
  components: {OfferComponent,ContactModal,FooterSection},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {t,locale} = useI18n()

    const contactFormOpen = ref(false);
    let data = ref({
      title: "",
      description: "",
      dates: [
        {
          data: "",
          dateInfo: [
            {
              id: 0,
              timeFrom: "",
              timeTo: "",
              timeTitle: "",
              timeDescription: "",
            }
          ]
        }
      ],
      packages: [
        {
          packageTitle: "",
          packageDescription: "",
          packageIncludes: [
            {
              icon: "",
              title: "",
              includes: [
                {
                  id: 0,
                  packageInfo: "",
                  tick: false
                }
              ]
            }
          ]
        }
      ],
      imageIds: []
    })
    const store = useStore();
    const dates = ref(data.value.dates);
    const route = useRouter();
    const photos = ref([]);
    const currentPhotoIndex = ref(0);
    const currentPhoto = ref("");
    const selectedDateInfo = ref(dates.value[0].dateInfos);
    async function load() {
      try {
        const res = store.dispatch("posts/getRealPost",props.id);
        res.then(async response => {
          if (response.status === 404) {
            await route.push('/');
          } else {
            let loc = locale.value;
            const lang = loc.startsWith("pl") ? "pl" : loc.startsWith("en") ? "eng" : "ger";
            data.value = response[lang];
            if (data.value.dates) {
              dates.value = data.value.dates;
              if (dates.value.length > 0)
                selectedDateInfo.value = dates.value[0].dataInfo;
            }
            await fetchImages(data.value.imageIds);

          }
        })
      } catch (error) {
        console.log('Error during fetch!', error);
        route.push('/');
      }
    }
    watch(locale, () => {
      load();
    });
    watch(
        () => props.id,
        () => {
          load();
        }
    );

    function changeSelectedDate(date) {
      if (!date.showInfo) date.showInfo = false;
      date.showInfo = !date.showInfo
      selectedDateInfo.value = date.dataInfo;
    }

    function isSelected(date) {
      return selectedDateInfo.value == date.dataInfo;
    }

      async function fetchImages(img) {
          const imagePromises = img.map(i => {
             return store.dispatch("posts/fetchImages",i).then(res=>{
               if(res !=undefined && res.url){
                 return res.url;
               }
             })
          });

          const resolvedImages = await Promise.all(imagePromises);
          photos.value = resolvedImages.filter(image => image !== null);
      }

    function nextPhoto() {
        if (photos.value.length === 0) {
            return;
        }

        currentPhotoIndex.value = (currentPhotoIndex.value + 1) % photos.value.length;
        const photoSrc = photos.value[currentPhotoIndex.value];
      const newPhoto = new Image();
      newPhoto.src = photoSrc;
      newPhoto.onload = () => {
        currentPhoto.value = photoSrc;

        const photoElements = document.querySelectorAll('.carousel__photo img');
        photoElements.forEach((element) => {
          element.classList.add('slide-in-out');

          element.addEventListener('transitionend', () => {
            element.classList.remove('slide-in-out');
          }, {once: true});
        });
      };
    }

    function startCarousel() {
      setInterval(() => {
        nextPhoto();
      }, 2000);
    }

    function parseVariables(key) {
      const val = t(key);
      const newVal = val.replaceAll("%faq%", '<a href="/faq" class="font-medium" @click="scrollToTop">FAQ</router-link>')
          .replaceAll("%contact%", '<a href="/contact" class="font-medium"  @click="scrollToTop">drop us a line!</router-link>')
      return newVal;
    }

    onMounted(async () => {
      await load();
      startCarousel();
    });
    return {
      data,
      contactFormOpen,
      currentPhoto,
      parseVariables,
      photos,
      isSelected,
      changeSelectedDate,
      selectedDateInfo,dates,locale
    }
  }
}
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: op .5s ease-in-out;
}

.e {
  transition: op .5s ease-in-out;
}

@keyframes op {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-container img, .flex-container div {
  width: 45%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-container img, .flex-container div {
    width: 100%;
  }
}

.carousel img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 30px;
}

.line {
  color: transparent;
  -webkit-text-stroke: 1.5px white;
  -moz-text-stroke: 1.5px white;
  text-stroke: 1.5px white;
}

.event-bg {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.carousel__photo {
  min-height: 400px;
  max-height: 400px;
  border-radius: 30px;
  overflow: hidden;
}

@media (min-width: 728px) {
  .carousel__photo {
    min-width: 70%;
    max-width: 1050px;
    min-height: 500px;
    max-height: 550px;
    border-radius: 30px;
    overflow: hidden;
  }
}

.line-h {
  line-height: 276%;
}

.carousel__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
