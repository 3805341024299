<template>
  <div class="w-11/12 3xl:w-10/12 mx-auto hidden lg:flex absolute top-0 left-0 right-0 z-40 flex items-end justify-end py-0.5 text-white">
    <div class="flex items-center justify-center py-0.5">
      <a href="https://www.facebook.com/EnduroGoatsTravel" class="mx-5"><font-awesome-icon icon="fa-brands fa-facebook" /></a>
      <a href="https://www.instagram.com/endurogoatstravel/?fbclid=IwAR32d1Rsv8h-RW8RxeWF_3siGP1mZP9UY9BhKAxjjJvHRCoHuOXSJMJo3hY"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
      <a href="https://www.youtube.com/channel/UCef-H6TZNQgCD6wtB5Q5k1w" class="ml-5 "><font-awesome-icon icon="fa-brands fa-youtube" /></a>
      <a href="https://www.tiktok.com/@endurogoatstravel" class="ml-5"><font-awesome-icon icon="fa-brands fa-tiktok" /></a>
      <a href="https://www.instagram.com/liagoesaround/" class="ml-5 hidden"><img alt="instagram" src="@/assets/social.png"/></a>
      <language-switcher @update="load"/>
    </div>
  </div>
  <nav class="fixed top-0 left-0 right-0 z-30 nav-bg">
    <div class="flex items-center justify-between w-11/12 3xl:w-10/12 mx-auto hidden lg:flex relative">
      <router-link to="/" class="flex items-start justify-start w-16" @click="scrollToTop"><img src="@/assets/logo2.png" alt="logo"/></router-link>
      <ul class="flex items-center justify-between w-2/6">
        <router-link class="px-1 mx-1" to="/" @click="scrollToTop">{{ $t('nav.home') }}</router-link>
        <router-link class="text-center" to="/upcoming" @click="scrollToTop">
          <v-menu
              open-on-hover
          >
            <template v-slot:activator="{ props }">
              <v-btn
                  class="!bg-transparent !shadow-none !text-white !normal-case !text-base !font-normal !tracking-wide !hover:text-black"
                  v-bind="props"
              >
                {{ $t('nav.upcoming') }}
                <font-awesome-icon :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'" class="ml-4"/>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link to="/upcoming"
                             @click="closeBurger()" class="">
                  <v-list-item-title class="text-black">All Events</v-list-item-title>
                </router-link>
                <div v-for="(event, index) in events" :key="index" class="my-1 ml-1 py-1">
                  <router-link :to="`/event/` + event.id" @click="scrollToTop" >
                    <v-list-item-title v-html="formatTitle(event.title)" class="text-black"></v-list-item-title>
                  </router-link>
                </div>
                  <router-link to="/private-trip"
                               @click="closeBurger()" class="">
                    <v-list-item-title class="text-black">Private Trip</v-list-item-title>
                  </router-link>

              </v-list-item>
            </v-list>
          </v-menu>
        </router-link>
        <router-link class="px-1 mx-1" to="/gallery" @click="scrollToTop">{{ $t('nav.gallery') }}</router-link>
        <router-link class="ml-1" to="/contact" @click="scrollToTop">{{ $t('nav.contact') }}</router-link>
      </ul>
    </div>
    <div class="flex items-center justify-between px-4 w-full h-4 lg:hidden menu-animation">
      <router-link class="px-2" to="/" @click="scrollToTop"><img alt="logo" class="w-8" src="@/assets/logo2.png"/>
      </router-link>
      <div class="flex items-center">
        <language-switcher class="text-white" @update="load"/>
        <button :class="{ 'hidden': openBurger, 'block': !openBurger }" class="flex flex-col items-center justify-between h-full px-2 button-menu"
                @click="openBurger = true">
          <span class="w-6 border border-white"></span>
          <span class="w-6 border border-white"></span>
          <span class="w-6 border border-white"></span>
        </button>
      </div>

      <button :class="{ 'hidden': !openBurger, 'block': openBurger }" class="flex flex-col items-center justify-between h-full px-2 button-close"
              @click="openBurger = false">
        <span class="bar top"></span>
        <span class="bar middle"></span>
        <span class="bar bottom"></span>
      </button>
      <ul v-if="openBurger"
          :class="{ 'hidden': !openBurger, 'block': openBurger }" class="flex items-start justify-start flex-col w-full h-full py-7 fixed top-0 right-0 left-0 bg-black" data-aos="fade-down">
        <div class="flex items-center justify-between w-full px-4 pb-[30px] border-b border-white">
          <router-link class="px-2" to="/" @click="closeBurger()"><img alt="logo" class="w-8" src="@/assets/logo2.png"/>
          </router-link>
          <button class="flex-center font-bold text-white text-xl px-2" @click="openBurger = !openBurger">X</button>
        </div>
        <div class="flex items-start justify-start flex-col mt-10 w-full text-2xl">
          <router-link class="px-4 mb-2 p-2 tracking-widest" to="/" @click="closeBurger()">{{ $t('nav.home') }}
          </router-link>
          <div class="w-full flex items-center justify-start mb-2">
            <button class="px-4 tracking-widest text-white p-2 " @click="toggleDropdown">{{ $t('nav.upcoming') }}
              <font-awesome-icon :icon="isDropdownOpen ? 'chevron-up' : 'chevron-down'"
                                 class="text-white text-sm ml-2"/>
            </button>
          </div>
          <div v-if="isDropdownOpen" class="flex items-start justify-start flex-col w-full mb-2 p-2 bg-gray">
            <router-link class="px-4 tracking-widest text-sm text-start  text-white p-2" to="/upcoming"
                         @click="closeBurger()">All Events
            </router-link>
            <div class="px-4 tracking-widest text-sm text-start text-white p-2" v-for="(event, index) in events" :key="index">
              <router-link :to="`/event/` + event.id" @click="closeBurger()">
                <v-list-item-title :style="{ whiteSpace: 'normal', wordWrap: 'break-word', overflowWrap: 'break-word' }" v-html="formatTitle(event.title)"></v-list-item-title>
              </router-link>
            </div>
            <router-link class="px-4 tracking-widest text-sm text-start text-white p-2" to="/private-trip"
                         @click="closeBurger()">Private Trip
            </router-link>
          </div>
          <router-link class="px-4 mb-2 p-2 tracking-widest" to="/gallery" @click="closeBurger()">
            {{ $t('nav.gallery') }}
          </router-link>
          <router-link class="px-4 p-2 tracking-widest" to="/contact" @click="closeBurger()">{{ $t('nav.contact') }}
          </router-link>
        </div>
        <div class="flex items-end justify-center h-full w-full mt-10">
          <div class="flex items-center justify-center h-full">
            <a class="mr-7" href="https://www.facebook.com/EnduroGoatsTravel">
              <font-awesome-icon class="w-8 h-8" icon="fa-brands fa-facebook"/>
            </a>
            <a href="https://www.instagram.com/endurogoatstravel/?fbclid=IwAR32d1Rsv8h-RW8RxeWF_3siGP1mZP9UY9BhKAxjjJvHRCoHuOXSJMJo3hY">
              <font-awesome-icon class="w-8 h-8" icon="fa-brands fa-instagram"/>
            </a>
            <a class="ml-7 " href="https://www.youtube.com/channel/UCef-H6TZNQgCD6wtB5Q5k1w">
              <font-awesome-icon class="w-8 h-8" icon="fa-brands fa-youtube"/>
            </a>
            <a class="ml-7" href="https://www.tiktok.com/@endurogoatstravel">
              <font-awesome-icon class="w-8 h-8" icon="fa-brands fa-tiktok"/>
            </a>
            <a class="mx-7 hidden" href="https://www.instagram.com/liagoesaround/"><img class=""
                                                                                        src="@/assets/social2.png"/></a>
          </div>
        </div>
      </ul>
    </div>
  </nav>
  <div v-if="!cookiesAccepted" class="fixed bottom-0 flex items-center justify-center w-full px-0 z-40">
    <div
        class="flex flex-col items-center justify-between w-full h-28 px-2 lg:px-4 py-2.5 md:py-6  border-t border-gray bg-white text-xs">
      <p>This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to
        their use.</p>
      <div class="flex items-center justify-center w-3/5 md:w-2/12 mt-2">
        <button class=" mr-4 px-4 py-2 bg-gray text-medium-white rounded-md text-xs" @click="accept">Accept</button>
        <router-link class="px-4 py-2 bg-gray text-medium-white rounded-md text-xs" to="/privacy">Privacy Policy
        </router-link>
      </div>
    </div>
  </div>
  <router-view/>
</template>
<script>
import {onMounted, ref} from "vue";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import {useCookies} from "vue3-cookies";
import {useRouter} from 'vue-router';
import {useStore} from "vuex";

export default {
  components: {LanguageSwitcher},
  setup() {
    const openBurger = ref(false);
    const isDropdownOpen = ref(false);
    const events = ref([]);
    const store = useStore();
    onMounted(async () => {
      load()
    });
async function load() {
  try {
    await store.dispatch('posts/fetchPosts');
    const locale = localStorage.getItem('locale');
    const lang = locale.startsWith('pl') ? 'Pl' : locale.startsWith('en') ? 'Eng' : 'Ger';

    events.value = store.getters['posts/getPosts'].map(post => {
      return {
        id: post.id,
        title: post[`title${lang}`]
      };
    });

  } catch (error) {
    console.log('All posts error:', error);
  }
}
    function toggleDropdown() {
      isDropdownOpen.value = !isDropdownOpen.value;
    }

    function closeBurger() {
      console.log(openBurger,openBurger.value)
      openBurger.value = !openBurger.value
      scrollToTop()
      toggleDropdown()
    }

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    const {cookies} = useCookies();
    const cookiesAccepted = ref(false);

    function accept() {
      this.cookies.set("accept-cookies", 'true');
      this.cookiesAccepted = true;
    }


    function formatTitle(title) {
      const boldWords = ['Enduro', 'Muay Thai']
      let formattedTitle = title
      boldWords.forEach(word => {
        formattedTitle = formattedTitle.replace(
            new RegExp(`(${word})`, 'g'),
            '<strong>$1</strong>'
        )
      })
      return formattedTitle
    }

    const router = useRouter();

    const getLink = (title) => {
      if (title === 'Enduro Cyprus May 7-11') {
        return {path: '/event-enduro'};
      } else if (title === 'Private Trip') {
        return {path: '/private-trip'};
      } else if (title === 'Enduro Sea & Rocks - Cyprus, June 18-22') {
        return {path: '/trip-june'};
      } else if (title === 'Enduro Summer Peaks - Cyprus, August 27 - 31') {
        return {path: '/trip-august'};
      } else if (title === 'Enduro Fall is in the air - Cyprus, October 1 - 5') {
        return {path: '/trip-october'};
      } else if (title === 'Enduro & Muay Thai Rev & punch - Cyprus, November 5 - 10') {
        return {path: '/trip-november'};
      } else if (title === 'Enduro Winter Escape - Cyprus, 3 - 7 December') {
        return {path: '/trip-december'};
      }
    };
    return {
      formatTitle,
      router,
      getLink,
      openBurger,
      scrollToTop,
      closeBurger,
      isDropdownOpen,
      toggleDropdown,
      cookiesAccepted,
      accept,
      cookies,
      events,store,load
    }
  },
  mounted() {
    let c = this.cookies.get("accept-cookies");
    if (c == null) {
      this.cookiesAccepted = false;
    }
    this.cookiesAccepted = c != null && this.cookies.isKey("accept-cookies") && c.toLowerCase() == 'true';
  },
}
</script>
<style lang="scss">
#app {
  font-family: 'Red Hat Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 35px 0 25px 0;

  a {
    color: white;
    transition: color .2s;
  }

  a:hover {
    color: #B4B4B4FF;
  }
}

.nav-bg {
  background-color: rgba(0, 0, 0, .7);
}

@media (min-width: 768px) {
  nav {
    padding: 15px 0 10px 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300;400;500;600;700&display=swap');
.red {
  font-family: 'Red Hat Text', sans-serif;
  font-display: swap;
}

.line-h {
  line-height: 276%;
}

.dropdown-menu {
  position: absolute;
  top: 65%;
  min-width: 200px;
  max-width: 250px;
  padding: 20px 10px 10px 10px;
  color: white;
  text-align: start;
  font-weight: 300;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1;
  display: none;

  li {
    margin-bottom: 15px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}

.dropdown-toggle:focus + .dropdown-menu,
.dropdown-menu:focus-within {
  display: block;
}

.button-menu {
  transition: transform 0.3s ease-in-out;
}

.button-menu span {
  display: block;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: white;
}

/* Style dla ikony "X" */
.button-close {
  transition: transform 0.3s ease-in-out;
}

.dropdown-btn {
  background-color: transparent;
  color: white;
  border: none;
  box-shadow: none;
  text-transform: lowercase;
}

.dropdown-btn:hover {
  color: black;
}

.button-close .bar {
  display: block;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: white;
}

.button-close .top {
  transform: rotate(45deg) translate(3px, 3px);
}

.button-close .middle {
  opacity: 0;
}

.button-close .bottom {
  transform: rotate(-45deg) translate(3px, -3px);
}

/* Animacja otwierania */
.open-enter-active,
.open-leave-active {
  transition: all 0.3s ease-in-out;
}

.open-enter,
.open-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.v-btn__overlay {
  background-color: transparent !important;
}
.text-wrapp {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
/* Animacja zamykania */
.close-enter-active,
.close-leave-active {
  transition: all 0.3s ease-in-out;
}

.close-enter,
.close-leave-to {
  transform: translateY(0%);
  opacity: 1;
}
</style>
