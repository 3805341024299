import http from './service-base';

class LoginService {

     loginUser(loginForm){
         return http
             .post( 'api/auth/login', {
                 username: loginForm.username,
                 password: loginForm.password
             })
             .then(response => {
                 if (response.data.jwttoken) {
                     localStorage.setItem('user', JSON.stringify(response.data.jwttoken));
                 }
                 return response.data;
             },err=>{
                 return err.response;
             });
    }
}
export  default  new LoginService();
