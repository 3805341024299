<template>
  <div class="flex-center-col h-full w-full">
    <div class="bg-img relative">
      <div class="absolute inset-0 dark-color"></div>
      <div class="flex-center absolute inset-0 px-4 lg:px-0">
        <div class="flex-center-col w-full lg:w-9/12 mx-auto text-white">
          <h1 class="title text-4xl lg:text-6xl lg:text-9xl uppercase font-medium">{{$t("contact.title")}}</h1>
          <h3 class="text-sm lg:text-lg font-light mt-4 lg:mt-0 w-5/6 lg:w-3/6">{{$t("contact.des")}}</h3>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between flex-col lg:flex-row w-full lg:w-8/12 mx-auto py-10 lg:py-20">
      <div class="flex-center-col mt-10 lg:mt-0 mb-16 lg:mb-0">
        <font-awesome-icon icon="fa-solid fa-location-dot" class="text-medium-green text-2xl lg:text-5xl"/>
        <p class="my-4 text-lg font-medium">{{$t("contact.buttons.first-btn")}}</p>
        <button @click="openForm = true"  class="offer-btn">{{$t("contact.buttons.first-action")}}</button>
      </div>
      <div class="flex-center-col mb-16 lg:mb-0">
        <font-awesome-icon icon="fa-solid fa-comment" class="text-medium-green text-2xl lg:text-5xl"/>
        <p class="my-4 text-lg font-medium">FAQ</p>
        <router-link to="/faq" class="offer-btn">{{$t("contact.buttons.second-action")}}</router-link>
      </div>
      <div class="flex-center-col mb-16 lg:mb-0">
        <font-awesome-icon icon="fa-solid fa-envelope-circle-check"  class="text-medium-green text-2xl lg:text-5xl"/>
        <p class="my-4 text-lg font-medium lg:w-4/5">{{$t("contact.buttons.third-btn")}}</p>
        <button @click="openForm = true" class="offer-btn">{{$t("contact.buttons.third-action")}}</button>
      </div>
    </div>
   <ContactModal :show="openForm"  @close="openForm = !openForm"/>
    <FooterSection/>
  </div>
</template>

<script>
import FooterSection from "@/components/FooterSection";
import ContactModal from "@/components/ContactModal.vue";
import { ref} from "vue";

export default {
  components: {ContactModal, FooterSection},
  setup() {

    const openForm = ref(false);
   return {openForm}
  }
}
</script>

<style scoped>
.bg-img {
  background-image: url("@/assets/contactbanner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 65vh;
  width: 100%;
}

.dark-color {
  background-color: rgba(0, 0, 0, .7);
}

.title {
  color: transparent;
  -webkit-text-stroke: .7px white;
  -moz-text-stroke: .7px white;
  text-stroke: .7px white;
}



input:focus, textarea:focus, select:focus {
  outline: none;
}

input, textarea, select {
  border: 1px solid #232323;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .title {
    -webkit-text-stroke: 1.5px white;
    -moz-text-stroke: 1.5px white;
    text-stroke: 1.5px white;
  }
  .bg-img {
    background-image: url("@/assets/contact1.jpg");
    background-position:center;
    height:75vh;
  }
  .dark-color {
    background-color: rgba(0, 0, 0, .5);
  }
}
</style>
