<template>
  <div class="w-11/12 lg:w-4/12 mx-auto h-full my-32 bg-yellow-accent-2">
    <div class="flex items-center justify-center w-full">
      <p class="uppercase red text-3xl tracking-wider font-bold">imprint</p>
    </div>
    <div class="flex flex-col items-center lg:items-start w-full">
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p>According to § 5 TMG</p>
        <p>Site operator:</p>
        <p>Hodor Natalia Matykowska</p>
        <p>Zamenhofa 4/30</p>
        <p>81-218 Gdynia, Poland</p>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">Represented by:</p>
        <p>Natalia Matykowska</p>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">Contact</p>
        <a href="tel:+35799868255">Telephone: +357 99 868255</a>
        <a href="mailto:contact@endurogoats.com">Email: contact@endurogoats.com</a>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">Tax ID</p>
        <p>Sales tax identification number according to § 27 a sales tax law:</p>
        <p>PL5862207115</p>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">Copyright</p>
        <p>All content on this website is protected by copyright and may not be reproduced, published, duplicated or used in any other way without the written permission of the website operator.</p>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">Liability for links</p>
        <p class="text-center lg:text-start">Our offer contains links to external third-party websites over whose content we have no influence. We therefore cannot assume any liability for this external content. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal content was not apparent at the time of linking. However, permanent control of the content of the linked pages is unreasonable without concrete evidence of a legal violation. If we become aware of any legal violations, we will immediately remove such links.
        </p>
      </div>
      <div class="flex flex-col items-center lg:items-start mt-12 text-sm grej">
        <p class="font-bold">copyright</p>
        <p class="text-center lg:text-start">The content and works on these pages created by the site operators are subject to German copyright law. Reproduction, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this page are only permitted for private, non-commercial use. If the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, contents of third parties are marked as such. Should you nevertheless become aware of a copyright infringement, we ask that you notify us accordingly. If we become aware of any legal violations, we will immediately remove such content.</p>
      </div>
    </div>
  </div>
</template>




<script>
export default {

}
</script>

<style scoped>
.grej {
  color: #808284;
}
</style>