import axios from "axios";

const httpPost = axios.create({
    baseURL: 'https://enduro-backend.herokuapp.com/',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer `+JSON.parse(localStorage.getItem('user'))??"",
        'Access-Control-Allow-Origin': 'https://enduro-backend.herokuapp.com/'
    },
});

export default httpPost;
