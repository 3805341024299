import http from './service-base';
import httpPost from './http-post-service';

class PostServices {
    getAllPosts(){
        return http.get("/api/public/postlangs/allPosts");
    }

    getAll(){
        return http.get("/api/public/postlangs/all");
    }
    createPost(data, token) {
            return httpPost.post("api/public/postlangs/create", data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+token
                    }
                });
        }

    updatePost(data,id, token) {
        return httpPost.put("api/public/postlangs/"+id, data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+token
                }
            });
    }
    async getPost(id) {
        return http.get(`api/public/postlangs/`+id)
    }
    changeActive(id, value,token) {
        return httpPost.post(`api/public/postlangs/changeActive?postId=`+id+`&value=`+value, {}, {
            headers: {
                'Authorization': `Bearer `+token
            }
        });
    }
    delete(id, token) {
        return httpPost.delete(`/api/public/postlangs/`+id, {}, {
            headers: {
                'Authorization': `Bearer `+token
            }
        });
    }

    duplicate(id, token) {
        return httpPost.post(`/api/public/postlangs/duplicate/`+id, {}, {
            headers: {
                'Authorization': `Bearer `+token
            }
        });
    }

}
export  default  new PostServices();
