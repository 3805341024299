<template>
  <div class="w-screen h-full pt-32 px-64 bg-medium-white">

    <v-card>
      <v-btn v-if="!state.update" class="mt-2" @click="createPost">{{
          state.update ? 'Update Post' : 'Create Post'
        }}
      </v-btn>
      <v-btn v-if="state.update" class="mt-2" @click="updatePost">{{
          state.update ? 'Update Post' : 'Create Post'
        }}
      </v-btn>

      <v-btn class="mt-2" to="/admin">BACK</v-btn>
      <div v-if="state.loading">
        <div class="fingerprint-spinner">
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
          <div class="spinner-ring"></div>
        </div>
      </div>
      <div>
        <h1>Dodaj zdjecia</h1>
        <input
            ref="fileInput"
            accept="image/*"
            class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            multiple type="file" @change="handleImageInput"/>
        <div class="flex flex-wrap gap-2.5 my-4 flex flex-wrap justify-center items-center">
          <div v-for="(select,index) in imageData" :key="select.id"
               class="flex justify-center items-center flex-wrap flex-col">
            <img :src="select.url" alt="preview" height="200" width="200"/>
            <v-btn class="mt-2" @click="deleteImage(select,index)">X</v-btn>
            <span v-if="select.uploadPercentage">{{ select.uploadPercentage }}% / 100%</span>
            <span v-if="select.uploaded">Uploaded! Id:{{ select.id }}</span>
          </div>
        </div>
      </div>
      <v-tabs v-model="activeTabIndex" class="mb-4">
        <v-tab v-for="(language, index) in languages" :key="index" :value="language">{{
            language
          }}
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-window v-model="activeTabIndex">
          <v-window-item value="Polish">
            <v-text-field v-model="title[0]" clearable label="Title" placeholder="Tytul wyjazdu "
                          variant="solo"/>
            <v-textarea counter v-model="description[0]" clearable label="Description"
                          placeholder="Opis wyjazdu "
                          variant="solo"/>
            <div>
              <div v-for="(l,index) in lines[0]" :key="index"
                   class="flex justify-center items-center">
                <v-text-field v-model="lines[0][index]" :label="`Event Description #${index}`"></v-text-field>
                <v-btn color="primary" @click="removeDesc(lines[0],index)">Remove Desc</v-btn>
              </div>
              <v-btn color="secondary" size="large" @click="addLine(lines[0])">Add event description
              </v-btn>
            </div>
            <DateCreatorComponent v-model="dates[0]"/>
            <PackagesCreatorComponent v-model="packages[0]" @dataChange="handlePackageUpdate"/>
          </v-window-item>

          <v-window-item value="German">
            <v-text-field v-model="title[1]" clearable label="Title" placeholder="Tytul wyjazdu "
                          variant="solo"/>
            <v-textarea counter v-model="description[1]" clearable label="Description"
                          placeholder="Opis wyjazdu "
                          variant="solo"/>
            <div>
              <div v-for="(l,index) in lines[1]" :key="index"
                   class="flex justify-center items-center">
                <v-text-field  :label="`Event Description #${index}`" v-model="lines[1][index]"></v-text-field>
                <v-btn color="primary" @click="removeDesc(lines[1],index)">Remove Desc</v-btn>
              </div>
              <v-btn color="secondary" size="large" @click="addLine(lines[1])">Add event description
              </v-btn>
            </div>
            <DateCreatorComponent v-model="dates[1]"/>
            <PackagesCreatorComponent v-model="packages[1]" @dataChange="handlePackageUpdate"/>
          </v-window-item>

          <v-window-item value="English">
            <v-text-field v-model="title[2]" clearable label="Title" placeholder="Tytul wyjazdu "
                          variant="solo"/>
            <v-textarea counter v-model="description[2]" clearable label="Description"
                          placeholder="Opis wyjazdu "
                          variant="solo"/>
            <div>
              <div v-for="(l,index) in lines[2]" :key="index"
                   class="flex justify-center items-center">
                <v-text-field  :label="`Event Description #${index}`" v-model="lines[2][index]"></v-text-field>
                <v-btn color="primary" @click="removeDesc(lines[2],index)">Remove Desc</v-btn>
              </div>
              <v-btn color="secondary" size="large" @click="addLine(lines[2])">Add event description
              </v-btn>
            </div>
            <DateCreatorComponent v-model="dates[2]"/>
            <PackagesCreatorComponent v-model="packages[2]" @dataChange="handlePackageUpdate"/>
          </v-window-item>
        </v-window>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>
import DateCreatorComponent from "@/views/adminpanel/new/panel/components/DateCreatorComponent.vue";
import {computed, isProxy, onMounted, reactive, ref, toRaw} from "vue";
import PackagesCreatorComponent
  from "@/views/adminpanel/new/panel/components/PackagesCreatorComponent.vue";
import ImageService from "@/services/image-service";
import {useStore} from "vuex";

export default {
  name: "PostCreator",
  components: {PackagesCreatorComponent, DateCreatorComponent},
  props: {
    id: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    const state = reactive({
      post: null,
      loading: false,
      error: null,
      update: false,
    });
    const fileInput = ref(null);
    const jsonData = ref('');

    const imageData = ref([]);
    const title = ref([]);
    const lines = ref([[], [], []]);
    const description = ref([]);
    const imageIds = computed(() => {
      const uniqueIds = new Set(imageData.value.map((select) => select.id));
      return Array.from(uniqueIds);
    });
    function addLine(d) {
      d.push("");
    }

    function removeDesc(line, index) {
      line.splice(index, 1);
    }

    const handleImageInput = (event) => {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        uploadFiles(files[i]);
      }

      event.target.value = '';
    };

    const deleteImage = (select, index) => {
      const id = select.id;
      ImageService.deleteImage(id).then(i => {
        console.log("Deleted: ", i.data);
        imageData.value.splice(index, 1)

      })

    };

    async function getImageUrl(img, fetchCallback) {

      ImageService.getImageUrl(img).then(i => {
        const data = i.data;
        const imageUrl = URL.createObjectURL(data);
        fetchCallback(imageUrl)
      })

    }

    function fetchImagesFromPost(images) {
      images.forEach(i => {
        const obj = {
          id: i,
          uploaded: ref(true),
        };

        getImageUrl(i, img => {
          obj.url = img;
          imageData.value.push(obj);
        });

      })
    }

    const dates = reactive([[
      {
        id: 0,
        data: "",
        dataInfo: [
          {
            id: 0,
            timeFrom: "",
            timeTo: "",
            timeTitle: "",
            timeDescription: "",
          },
        ],
      },
    ], [
      {
        id: 0,
        data: "",
        dataInfo: [
          {
            id: 0,
            timeFrom: "",
            timeTo: "",
            timeTitle: "",
            timeDescription: "",
          },
        ],
      },
    ], [
      {
        id: 0,
        data: "",
        dataInfo: [
          {
            id: 0,
            timeFrom: "",
            timeTo: "",
            timeTitle: "",
            timeDescription: "",
          },
        ],
      },
    ]]);
    const packages = reactive([[
      {
        id: 0,
        desc: "",
        title: "",
        packagesInclude: [
          {
            id: 0,
            packageTitle: "",
            packageIcon: "",
            includes: [
              {
                id: 0,
                packageInfo: "",
                tick: false
              }
            ],
          }
        ]
      }
    ], [
      {
        id: 0,
        desc: "",
        title: "",
        packagesInclude: [
          {
            id: 0,
            packageTitle: "",
            packageIcon: "",
            includes: [
              {
                id: 0,
                packageInfo: "",
                tick: false
              }
            ],
          }
        ]
      }
    ], [
      {
        id: 0,
        desc: "",
        title: "",
        packagesInclude: [
          {
            id: 0,
            packageTitle: "",
            packageIcon: "",
            includes: [
              {
                id: 0,
                packageInfo: "",
                tick: false
              }
            ],
          }
        ]
      }
    ]]);
    const activeTabIndex = ref(0);
    const languages = ['Polish', 'German', 'English'];
    const store = useStore();

    const fetchData = async () => {
      const postId = props.id || 'default';
      state.loading = true;

      if (postId === 'default') {
        state.update = false;
        state.loading = false;

        return;
      }
    const data = store.getters['posts/getRealPostById'](postId);
      if (data == undefined) {
        store.dispatch("posts/getRealPost",postId).then(i => {
          state.update = true;
          loadPost(i);
          state.loading = false;
        }, err => {
          state.error = err;
        })
      }else {
        loadPost(data);
        state.loading = false;

      }};
    onMounted(fetchData);

    const uploadFiles = (file) => {
      const obj = {
        id: imageData.value.length + 1,
        file: file,
        url: URL.createObjectURL(file),
        uploadPercentage: ref(0),
        uploaded: ref(false),
      };

      imageData.value.push(obj);

      ImageService.uploadFile(file, (per) => {
        obj.uploadPercentage.value = per;
      })
      .then((i) => {
        const uploaded = i.data;

        obj.id = uploaded.id;
        delete obj.uploadPercentage;
        obj.uploaded.value = true;
        getImageUrl(uploaded.id, img => {
          obj.url = img;
        });
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
    };

    function loadPost(response) {
      const images = response['pl'].imageIds;
      languages.forEach((language, i) => {
        const languageCode = {
          Polish: 'pl',
          German: 'ger',
          English: 'eng'
        }[language];
        if (!response[languageCode]) {
          return;
        }
        title.value[i] = response[languageCode].title
        description.value[i] = response[languageCode].description
        dates[i] = response[languageCode].dates
        packages[i] = response[languageCode].packages
        lines.value[i]=response[languageCode].lines;
      });
      fetchImagesFromPost(images);
      state.loading = false;
    }

    function createNewPost() {
      const post = {
        eng: {},
        pl: {},
        ger: {}
      };

      languages.forEach((language, i) => {
        const titleValue = title.value[i];
        const descriptionValue = description.value[i];
        const date = isProxy(dates[i]) ? toRaw(dates[i]) : dates[i];
        const packageData = isProxy(packages[i]) ? toRaw(packages[i]) : packages[i];
        const li = isProxy(lines.value[i]) ? toRaw(lines.value[i]) : lines.value[i];

        const languageCode = {
          Polish: 'pl',
          German: 'ger',
          English: 'eng'
        }[language];

        post[languageCode].title = titleValue ?? '';
        post[languageCode].description = descriptionValue ?? '';
        post[languageCode].imageIds = imageIds.value ?? [];
        post[languageCode].dates = date;
        post[languageCode].packages = packageData;
        post[languageCode].lines = li;
      });
      const jsonData = JSON.stringify(post);
      const user = JSON.parse(localStorage.getItem('user'));
      const r = store.dispatch("posts/createPost",{ data: jsonData, token: user.token });
      r.then(i => {
        state.loading = false;
        store.commit('posts/ADD_POST', i);
        alert("Post created!");
      }, err => {
        alert("Error during creating post:")
        console.log(err);
      });
    }
    function updatePost() {
      const post = {
        eng: {},
        pl: {},
        ger: {}
      };

      languages.forEach((language, i) => {
        const titleValue = title.value[i];
        const descriptionValue = description.value[i];
        const date = isProxy(dates[i]) ? toRaw(dates[i]) : dates[i];
        const packageData = isProxy(packages[i]) ? toRaw(packages[i]) : packages[i];
        const li = isProxy(lines.value[i]) ? toRaw(lines.value[i]) : lines.value[i];

        const languageCode = {
          Polish: 'pl',
          German: 'ger',
          English: 'eng'
        }[language];

        post[languageCode].title = titleValue ?? '';
        post[languageCode].description = descriptionValue ?? '';
        post[languageCode].imageIds = imageIds.value ?? [];
        post[languageCode].dates = date;
        post[languageCode].lines = li;
        post[languageCode].packages = packageData;
      });
      jsonData.value = JSON.stringify(post);
      const user = JSON.parse(localStorage.getItem('user'));
      store.dispatch("posts/updatePost",{ data: jsonData.value, id: props.id, token: user }).then(i=>{
        alert("Post updated!"+i.id);
      }, err => {
        alert("Error during updating post:")
        console.log(err);
      })
    }

    function createPost() {
      state.loading = true;
      createNewPost()

    }

    const handleDataUpdate = (newData) => {
      dates[activeTabIndex] = newData;
    }

    const handlePackageUpdate = (newData) => {
      packages[activeTabIndex] = newData;
    }

    return {
      activeTabIndex,
      languages,
      dates,
      handleDataUpdate,
      handlePackageUpdate,
      packages,
      description,
      title,
      imageData,
      createPost,
      lines,
      state,
      updatePost,
      uploadFiles,
      handleImageInput,
      deleteImage,
      fileInput, removeDesc, addLine
    };
  }
}
</script>

<style scoped>

</style>
